.confirm-dialog-wrapper {
  padding: 0.75rem 1rem 0 1rem;

  .confirm-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem !important;
  }

  i {
    margin-right: 0.3rem;
    margin-bottom: 0 !important;
  }
}
