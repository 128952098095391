.custom-tabbing {
  &.p-tabview {
    .p-tabview-nav-content {
      position: relative;
      @include bp(sm-max) {
        margin: 0 20px;
      }
    }
    .p-tabview-nav-btn {
      width: 14px;
      height: 47px;
      background-color: transparent;
      display: none;
      &:hover {
        background-color: transparent !important;
      }
      &.p-tabview-nav-prev {
        left: 5px;
      }
      &.p-tabview-nav-next {
        right: 5px;
      }
      @include bp(sm-max) {
        display: block;
      }
    }
  }
  .p-tabview-nav {
    li {
      .p-tabview-nav-link {
        padding: 10px 20px;
        height: 50px;
        @include bp(sm-max) {
          padding: 10px;
        }
        .dark-theme & {
          border-color: var(--border-light-color);
          color: var(--title-dark-text);
        }
      }
    }
    .dark-theme & {
      border-color: var(--border-light-color);
    }
  }
}

.schedule-tabbing-wrap {
  // margin: 0 -24px;
  .search-block {
    max-width: 100%;
    .search-input-wrap {
      width: 100%;
    }
  }
  .p-tabview-panels {
    padding-left: 0;
    padding-right: 0;
    padding-top: 24px;
  }
  .p-tabview-nav-container {
    margin: 0 -24px;
    .p-tabview-nav {
      li {
        .p-tabview-nav-link {
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
  .interviewer-list-wrap {
    min-height: calc(100vh - 265px);
  }
  .interviewer-list-block {
    margin: 16px 0 -16px !important;
    flex-direction: column;
    overflow-x: auto;
    li {
      cursor: pointer;
      border-bottom: 1px solid var(--surface-d);
      padding: 13px 0;
      color: var(--heading-text);
      font-weight: 400;
      flex: 0 0 100%;
      max-width: 100%;
      white-space: nowrap;
      @include bp(sm-max) {
        padding: 12px 0;
      }
      .dark-theme & {
        border-color: var(--border-light-color);
        color: var(--title-light-text2);
      }
      span {
        color: var(--heading-text);
        font-weight: 400;
        .dark-theme & {
          color: var(--title-light-text2);
        }
      }
      .profile-img {
        height: 40px !important;
        width: 40px !important;
        font-size: 14px;
      }
      &.empty-result {
        border: none;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .content-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-block {
          display: flex;
          align-items: center;
        }
        .right-block {
          display: flex;
          align-items: center;
        }
        .technology-list {
          flex-wrap: nowrap;
          margin: 0 0 -10px 0;
          li {
            flex: none;
            max-width: none;
            padding: 0px 10px;
            border: none;
            &:last-child {
              margin-right: 0;
            }
          }
          // .technology-badge.java-badge
        }
        .experience {
          padding: 0 15px;
          display: flex;
          align-items: center;
          font-style: italic;
          white-space: nowrap;
          span {
            margin-left: 5px;
            font-style: normal;
          }
        }
      }
    }
  }
}

.availiblity-tabbing {
  position: relative;
  @include bp(sm-max) {
    margin-top: 55px;
  }
  &::after {
    content: '';
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 48px;
    background-color: #dee2e6;
    .dark-theme & {
      background-color: var(--border-light-color);
    }
  }
  .p-tabview-panels {
    padding: 25px 20px;
  }
  .add-slot-btn {
    position: absolute;
    right: 20px;
    width: 45px;
    min-width: auto;
    top: 0;
    @include bp(lgl-max) {
      right: 15px;
    }
    @include bp(sm-max) {
      left: 15px;
      right: auto;
      top: -55px;
    }
  }
  .p-tabview-nav {
    border: none;
  }
  .p-tabview-nav-container {
    max-width: calc(100% - 75px);
    @include bp(lgl-max) {
      max-width: calc(100% - 70px);
    }
    @include bp(sm-max) {
      max-width: 100%;
    }
  }
  .availiblity-form-row {
    margin: 0 -12px 24px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    max-width: 725px;
    @include bp(xxs-max) {
      padding-right: 50px;
      position: relative;
    }
    .MuiTouchRipple-root {
      display: none !important;
    }
    .availiblity-form-col {
      padding: 0 12px;
      flex: 0 0 calc(50% - 33px);
      max-width: calc(50% - 33px);
      @include bp(xxs-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 20px;
      }
      .p-button {
        padding: 4px;
        height: 50px;
        width: 50px;
      }
      &.btn-col {
        flex: 0 0 56px;
        max-width: 56px;
        @include bp(xxs-max) {
          position: absolute;
          right: 0;
          top: 0;
        }
        .delete-slot-btn {
          width: 45px;
          min-width: auto;
          color: var(--border-gray) !important;
          .dark-theme & {
            color: var(--title-dark-text);
          }
        }
      }
    }
    .custom-form-group {
      .MuiButtonBase-root {
        padding: 4px;
        margin: -1px -12px 0 0;
      }
    }
  }
  .switch-wrapper {
    margin-left: 15px;
    label {
      line-height: 1;
    }
    .dark-theme & {
      color: var(--title-light-text2);
    }
  }
}
