* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $fontSize;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--surface-h);
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.dark-theme {
    background-color: var(--surface-body);
    color: var(--title-dark-text);
  }
}

a,
button {
  text-decoration: none;
  color: var(--primary-color);
}

// .layout-theme-light {
//   background-color: var(--surface-100);
// }
.p-datatable.p-datatable-sm {
  .p-datatable-header {
    background-color: #ffffff;
  }
}
.p-inputswitch {
  width: 2.5rem;
  height: 1.5rem;
  .p-inputswitch-slider {
    &:before {
      width: 1rem;
      height: 1rem;
      margin-top: -0.5rem;
    }
  }
  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      &:before {
        transform: translateX(1rem);
        -webkit-transform: translateX(1rem);
        -moz-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
        -o-transform: translateX(1rem);
      }
    }
  }
}
h6.no-underline {
  position: relative;
  &::before {
    content: '';
    background-color: var(--blue-500) !important;
    height: 1.5px;
    width: 0;
    position: absolute;
    top: calc(100% - 1.5px);
    left: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
  }
  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.rbc-month-row {
  min-height: 130px !important;
  overflow: visible !important;
  flex-basis: auto !important;
}

.rbc-event-label {
  display: none !important;
}

.space-between {
  display: flex;
  justify-content: space-between !important;
}

.calender-tooltip {
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  z-index: 1000000;
  font-size: 12px;
  line-height: 16px;
  color: black;
}

.feedback-label {
  width: 100%;
  margin: 0 0 10px 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
}

.copy-icon {
  font-size: 12px !important;
  cursor: pointer !important;
}

.rdtPicker {
  min-width: 100% !important;
}

.filter-margin {
  margin-right: 10px !important;
}

.react-datepicker__input-container {
  input {
    width: 100% !important;
    background: transparent !important;
  }
}

.react-datepicker-popper {
  width: 40% !important;
}

.react-datepicker--time-only,
.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
  margin: 0 !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.candidate-list-email-text {
  font-size: 14px !important;
}

.unavailable-interviewer-text-color {
  color: red !important;
}

.pending-badge {
  background-color: #d99054 !important;
  color: #ffffff !important;
}

.schedule-badge {
  background-color: #daa521 !important;
  color: #ffffff !important;
}

.complete-badge {
  background-color: #7eb155 !important;
  color: #ffffff !important;
}

.reschedule-badge {
  background-color: #208fc7 !important;
  color: #ffffff !important;
}

.cancel-badge {
  background-color: #cb5555 !important;
  color: #ffffff !important;
}

.blink {
  color: red;
  padding: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-left: 8px;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

.dark-theme .blink {
  color: rgba(228, 230, 244, 1) !important;
  background: transparent;
  border: 1px solid rgba(228, 230, 244, 1);
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* Other styles */
.monthly_average .db_text span {
  background: #fff;
  display: inline-block;
  padding: 2px 7px 4px 7px;
  margin: 0 0 0 4px;
  border-radius: 3px;
  color: #cc71a0;
  line-height: 1;
}

/* Box sizing reset */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.full-width {
  width: 100% !important;
}

.clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999; /* Adjust color as needed */
}

.status-container {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}

.status-label {
  font-size: 80px;
  color: white;
  text-align: center;
}

.p-tabview-nav-container {
  position: relative !important;
}

.legends {
  position: absolute;
  height: 50px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 5px;

  span {
    display: inline-block !important;
    height: 12px !important;
    width: 12px !important;
    border-radius: 50%;
    margin-right: 6px;
  }
}

.legend-label {
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.pending-label {
  background-color: #d99054 !important;
}

.schedule-label {
  background-color: #daa521 !important;
}

.complete-label {
  background-color: #7eb155 !important;
}

.reschedule-label {
  background-color: #208fc7 !important;
}

.cancel-label {
  background-color: #cb5555 !important;
}

.interview-status {
  i {
    font-size: 1rem !important;
    margin-left: 2px;
  }
}

.grow-1 {
  flex-grow: 1 !important;
}

.align-center {
  align-items: center;
}

.position-relative {
  position: relative;
}

.unread-notification {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff4100;
}

body.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
  z-index: 1000001;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 1000001;
}

.justify-flex-end {
  display: flex;
  justify-content: flex-end;
}

.sub-technology {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400 !important;
  display: block;
  margin-bottom: 16px;
  color: var(--heading-text);
  .dark-theme & {
    color: var(--title-dark-text) !important;
  }
  &.hide-label {
    display: none;
  }
}

.sub-technology-color {
  width: 53% !important;
  margin-right: 5px !important;
  height: fit-content !important;
}

.dark-mode .rbc-off-range-bg {
  background: rgba(228, 230, 244, 0.2) !important;
}

.dark-mode .rbc-today {
  background: rgba(228, 230, 244, 0.4) !important;
}

.dark-mode .rbc-label {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode .rbc-button-link {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode span[role='columnheader'] {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode .rbc-btn-group button {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode .rbc-btn-group button.rbc-active {
  color: #373a3c !important;
}

.dark-mode .rbc-btn-group button:hover {
  color: #373a3c !important;
}

.dark-mode .rbc-toolbar-label {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode .rbc-day-slot .rbc-time-slot {
  border-top: 1px solid transparent !important;
}

.dark-mode .rbc-toolbar button:active {
  color: #373a3c !important;
}

.dark-mode .rbc-toolbar button:focus {
  color: #373a3c !important;
}

.dark-theme .p-menu .p-menuitem-link:not(.p-disabled):hover {
  color: #373a3c !important;
  background: #eef2ff !important;
}
