// .layout-topbar-block {
//     &::after {
//         content: '';
//         height: 110px;
//         background: linear-gradient( 180deg, rgba(248, 247, 250, 70%) 44%, rgba(248, 247, 250, 43%) 73%, rgba(248, 247, 250, 0%));
//         backdrop-filter: blur(14px);
//         display: block;
//         position: fixed;
//         // left: 260px;
//         right: 0;
//         top: 0;
//         z-index: 946;
//         inset-block-start: -1rem;
//         inset-inline-end: 0;
//         inset-inline-start: 0;
//         mask: linear-gradient(var(--black), var(--black) 18%, transparent 100%);
//         -webkit-mask: linear-gradient(var(--black), var(--black) 18%, transparent 100%);
//         .dark-theme & {
//             background: linear-gradient( 180deg, rgba(37, 41, 60, 70%) 44%, rgba(37, 41, 60, 43%) 73%, rgba(37, 41, 60, 0%));
//         }
//         @include bp(smd-max) {
//             display: none;
//         }
//         display: none;
//     }
// }
.layout-topbar {
  position: fixed;
  height: 90px;
  z-index: 99;
  left: 24px;
  top: 0px;
  padding: 0 13px;
  background-color: var(--surface-h);
  transition: left $transitionDuration;
  display: flex;
  align-items: center;
  // box-shadow: 0px 3px 3px 0px #00000008;
  left: 287px;
  right: 37px;
  .sidebar-hide & {
    @include bp(smd-min-2) {
      left: 117px;
    }
  }
  @include bp(llg-max) {
    height: 80px;
    left: 261px;
    right: 11px;
    .sidebar-hide & {
      @include bp(smd-min-2) {
        left: 91px;
      }
    }
  }
  .dark-theme & {
    background: var(--surface-body);
  }
  @include bp(smd-max) {
    left: 15px;
    right: 15px;
    padding: 0 15px;
    justify-content: space-between;
    height: 64px;
  }
  .layout-topbar-logo {
    display: flex;
    align-items: center;
    color: var(--heading-text);
    font-size: 22px;
    line-height: 1.2;
    font-weight: 400;
    width: 300px;
    order: 2;
    text-transform: capitalize;
    .dark-theme & {
      color: var(--surface-a);
    }
    .logo-text {
      @include bp(smd-max) {
        display: none;
      }
    }
    img {
      height: 2.5rem;
      margin-right: 0.5rem;
    }
    span {
      text-align: center;
    }
    &:focus {
      @include focused();
    }
  }
  .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    min-width: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: background-color $transitionDuration;
    @include bp(xs-max) {
      width: 35px;
      height: 35px;
      min-width: 35px;
    }
    &:hover {
      color: var(--text-color);
      background-color: var(--surface-hover);
    }
    &:focus {
      @include focused();
    }
    i {
      font-size: 20px;
      @include bp(xs-max) {
        font-size: 16px;
      }
    }
    span {
      font-size: 1rem;
      display: none;
    }
    .active-menu-ic {
      display: none;
    }
  }
  .layout-menu-button {
    order: 1;
    display: none;
    @include bp(smd-max) {
      display: flex;
    }
    .dark-theme & {
      color: var(--surface-a);
    }
  }
  .layout-topbar-menu-button {
    display: none;
    i {
      font-size: 1.25rem;
    }
  }
  .layout-topbar-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    order: 3;
    .layout-topbar-button {
      margin-left: 1rem;
    }
    .theme-mode {
      // margin-right: -10px;
    }
  }
  .user-dropdown {
    cursor: pointer;
    .user-img {
      height: 35px;
      width: 35px;
      font-size: 14px;
      background-color: var(--primary-color);
      @include bp(xs-max) {
        height: 30px;
        width: 30px;
        font-size: 13px;
      }
    }
  }
  .MuiAvatar-root {
    background-color: var(--primary-color);
  }
  .topbar-mobile-logo {
    display: none;
    order: 2;
    margin-left: 10px;
    img {
      max-width: 100%;
      width: 145px;
    }
    @include bp(smd-max) {
      display: flex;
    }
  }
}

.user-name-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  color: var(--text-color);
  border-radius: $borderRadius;
  .dark-theme & {
    color: var(--title-dark-text);
  }
  img {
    width: 35px;
    height: 35px;
    border-radius: 25px;
    margin-right: 12px;
  }
  &:hover {
    background: var(--link-hover);
  }
  .dark-theme & {
    &:hover {
      background: var(--link-hover-bg);
      color: var(--title-dark-text);
    }
  }
  .user-img {
    margin-right: 10px;
    font-size: 16px;
  }
}

.notification-popup {
  padding: 4px 0;
  position: fixed !important;
  left: auto !important;
  right: 100px !important;
  top: 75px !important;
  display: block !important;
  @include bp(llg-max) {
    right: 75px !important;
    top: 65px !important;
  }
  @include bp(smd-max) {
    right: 70px !important;
    top: 55px !important;
  }
}

.notification-menu {
  width: 42px;
  height: 42px;
  .menu-link {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    position: relative;
    button {
      height: 42px;
      min-width: 42px;
      max-height: 42px;
      line-height: 42px;
    }
    i {
      font-size: 20px;
    }
    .MuiAvatar-root {
      background-color: var(--primary-color);
    }
    .notification-number {
      position: absolute;
      top: 2px;
      right: 6px;
      font-size: 12px;
      line-height: 1.23;
      font-weight: 400;
      background-color: #e57373;
      width: 18px;
      height: 18px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
}

.p-menu {
  &.user-menu-popup {
    position: fixed !important;
    left: auto !important;
    right: 57px !important;
    top: 75px !important;
    width: 230px;
    padding: 8px 0 4px;
    // margin: 15px -10px 0 0;
    display: block !important;
    @include bp(llg-max) {
      right: 37px !important;
      top: 65px !important;
    }
    @include bp(smd-max) {
      right: 25px !important;
      top: 55px !important;
    }
    .dark-theme & {
      background-color: var(--dark-boxes);
    }
    .user-name-wrapper {
      background-color: transparent !important;
    }
  }
  .p-menuitem {
    padding: 4px 8px;
    .p-menuitem-link {
      padding: 12px 16px;
      display: block;
      border-radius: $borderRadius;
      .dark-theme & {
        color: var(--title-dark-text);
      }
    }
  }
}

.topbar-menu-list {
  margin: 0 -8px;
  @include bp(xs-max) {
    margin: 0 -4px;
  }
  .topbar-menu-item {
    padding: 0 8px;
    @include bp(xs-max) {
      padding: 0 4px;
    }
    &.notification-wrapper {
      padding-left: 0;
    }
    &.profile-wrapper {
      background-color: var(--surface-a);
      padding-right: 0;
      border-radius: 18px;
      border: 1px solid var(--surface-a);
      cursor: pointer;
      .dark-theme & {
        background-color: transparent;
        border-color: var(--light-gray1);
      }
      @include bp(smd-max) {
        border-color: var(--light-gray6);
      }
      @include bp(sm-max) {
        background-color: transparent;
        padding-right: 4px;
        border: 0;
      }
      .user-role {
        padding: 0 12px 0 10px;
        font-size: 15px;
        line-height: 1.2;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include bp(sm-max) {
          display: none;
        }
      }
    }
  }
  .theme-button {
    .darkmode-icon {
      display: none;
    }
    .dark-theme & {
      .default-icon {
        display: none;
      }
      .darkmode-icon {
        display: block;
        font-size: 21px;
        line-height: 1;
        @include bp(xs-max) {
          font-size: 20px;
        }
      }
    }
  }
}

.layout-mobile-sidebar-active {
  .layout-topbar {
    .layout-topbar-button {
      .default-ic {
        display: none;
      }
      .active-menu-ic {
        display: block;
      }
    }
  }
}

@media (min-width: 991px) {
  .layout-topbar-block {
    &::after {
      width: calc(100% - 270px);
      margin: 0 0 0 auto;
      border-top-left-radius: 35px;
    }
  }
}

// @media (min-width: 1365px) {
//     .layout-topbar {
//         left: auto;
//         width: calc(100% - 324px);
//         right: 35px;
//     }
// }
@media (min-width: 992px) {
  .layout-menu-button {
    display: none;
  }
}

// @media (min-width: 1680px) {
//   .layout-topbar {
//     width: calc(100% - 449px);
//     right: 100px;
//   }
// }
@media (max-width: 991px) {
  .layout-topbar {
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    background-color: #fff;
    box-shadow: 0px 3px 3px 0px #00000008;
    .layout-topbar-logo {
      width: auto;
      order: 2;
      font-size: 1.35rem;
      padding: 0 5px 0 0;
    }
    .layout-menu-button {
      margin-right: 0;
      order: 1;
    }
    .layout-topbar-menu-button {
      margin-left: 0;
      order: 3;
      height: 2.5rem;
      min-width: 2.5rem;
      width: 2.5rem;
      i {
        font-size: 1rem;
      }
    }
    .layout-topbar-menu {
      margin-left: 0;
      flex-direction: column;
      &.layout-topbar-menu-mobile-active {
        display: block;
      }
      .layout-topbar-button {
        margin-left: 0;
        display: flex;
        width: 100%;
        height: auto;
        justify-content: flex-start;
        border-radius: 12px;
        padding: 1rem;
        i {
          font-size: 1rem;
          margin-right: 0.5rem;
        }
        span {
          font-weight: medium;
          display: block;
        }
      }
    }
  }
}
.dropdown_menu {
  .heading {
    display: flex;
    padding-bottom: 0.5rem;
    padding-right: 0.75rem;
    justify-content: space-between;
    align-items: center;

    .clear-all {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.notification-modal {
  max-width: 450px;
  min-width: 300px;
  overflow-y: auto;
  max-height: 200px;
}

.user-info {
  word-break: break-all !important;
  white-space: pre-wrap !important;

  &.new-notification {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      background: #ff4100;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      right: 14px;
    }

    .user-data p {
      font-weight: 600;
    }
  }

  .user-avtar {
    max-width: 48px;
    flex: 0 0 48px;

    .MuiAvatar-root {
      width: 48px;
      height: 48px;
      margin: 0;
    }
  }

  .user-data {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    padding: 0 0px 0 16px;
    word-break: break-word !important;

    span {
      color: #70778e;
      font-weight: 400;
      font-size: 10px;
      line-height: 1.5;
      display: block;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      margin: 0;
    }
  }
}
