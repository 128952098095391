// form grid
.form-row-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -24px;
  @include bp(sm-max) {
    margin-bottom: -20px;
  }
  .form-col {
    padding: 0 12px;
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 24px;
    @include bp(sm-max) {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
    &.full-width {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .form-group-outer {
      position: relative;
      .p-error {
        font-size: 12px;
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
      }
    }
  }
  .form-col-2 {
    padding: 0 8px;
    max-width: 33%;
    flex: 0 0 33%;
    margin-bottom: 24px;
    @include bp(sm-max) {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
    &.full-width {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .form-group-outer {
      position: relative;
      .p-error {
        font-size: 12px;
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
      }
    }
  }
}

.form-box-wrapper {
  .card-title {
    font-size: 20px;
    line-height: 1.16;
    margin-bottom: 16px;
    color: var(--heading-text);
    text-transform: capitalize;
    .dark-theme & {
      color: var(--title-dark-text);
    }
  }
}

// form group
.custom-form-group {
  position: relative;
  .pi-clock {
    position: absolute;
    top: 2px;
    right: 10px;
    color: var(--primary-color) !important;
    cursor: pointer;
  }
  label {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400 !important;
    display: block;
    margin-bottom: 16px;
    color: var(--heading-text);
    @include bp(sm-max) {
      font-size: 15px;
    }
    .dark-theme & {
      color: var(--title-dark-text) !important;
    }
    &.hide-label {
      display: none;
    }
  }
  input,
  textarea {
    height: 45px;
    line-height: 45px;
    border-radius: var(--border-radius);
    padding: 0 15px;
    border: 1px solid var(--border-gray);
    border-width: 1px !important;
    outline: 0;
    box-shadow: none;
    font-size: 16px;
    color: var(--black2);
    font-weight: 400;
    box-sizing: border-box;
    font-family: var(--font-family);
    @include bp(sm-max) {
      font-size: 15px;
    }
    .dark-theme & {
      color: var(--input-dark-text);
      border-color: var(--input-dark-text);
    }
    &:focus {
      border-color: var(--primary-color) !important;
      &::placeholder {
        opacity: 0 !important;
      }
      &::-webkit-input-placeholder {
        opacity: 0 !important;
      }
      &:-moz-placeholder {
        opacity: 0 !important;
      }
      &::-moz-placeholder {
        opacity: 0 !important;
      }
      &:-ms-input-placeholder {
        opacity: 0 !important;
      }
    }
    &::placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;
      transition: all 0.15s;
      .dark-theme & {
        color: var(--input-dark-text);
      }
    }
    &::-webkit-input-placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;
      .dark-theme & {
        color: var(--input-dark-text);
      }
    }
    &:-moz-placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;
      .dark-theme & {
        color: var(--input-dark-text);
      }
    }
    &::-moz-placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;
      .dark-theme & {
        color: var(--input-dark-text);
      }
    }
    &:-ms-input-placeholder {
      color: var(--light-gray2);
      opacity: 1 !important;
      .dark-theme & {
        color: var(--input-dark-text);
      }
    }
  }
  textarea {
    line-height: 1.3;
    padding: 10px 15px;
    height: 88px;
    resize: none;
  }
  .p-multiselect {
    height: 45px;
    width: 100%;
    line-height: 45px;
    border-radius: var(--border-radius);
    padding: 0 35px 0 15px;
    border: 1px solid var(--border-gray);
    box-shadow: none !important;
    font-size: 16px;
    color: var(--black2);
    font-weight: 400;
    @include bp(sm-max) {
      font-size: 15px;
    }
    .dark-theme & {
      background-color: transparent;
      color: var(--input-dark-text);
      border-color: var(--input-dark-text);
    }
    .p-multiselect-trigger {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      color: var(--border-gray);
      .dark-theme & {
        color: var(--input-dark-text);
      }
      .pi {
        font-size: 16px;
        @include bp(sm-max) {
          font-size: 15px;
        }
      }
    }
    .p-multiselect-label {
      color: var(--light-gray2);
      font-size: 16px;
      @include bp(sm-max) {
        font-size: 15px;
      }
      .dark-theme & {
        color: var(--input-dark-text);
      }
    }
    .p-multiselect-label-container {
      margin-top: -2px;
      .p-multiselect-label {
        padding: 0;
        .p-multiselect-token {
          padding: 0 8px;
          height: 30px;
          line-height: 30px;
          color: var(--black2);
          background-color: var(--surface-100);
          font-size: 14px;
          .p-multiselect-token-label {
            min-height: 10px;
          }
          .dark-theme & {
            color: var(--border-gray);
            background-color: var(--surface-body);
          }
        }
      }
    }
    &:focus {
      border-color: var(--primary-color);
    }
    &.p-inputwrapper-filled {
      .p-multiselect-trigger {
        color: var(--black2);
        .dark-theme & {
          color: var(--input-dark-text);
        }
      }
    }
  }
  .p-autocomplete {
    position: relative;
    width: 100%;
    &::before {
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      width: 15px;
      background-color: transparent;
      content: '';
      border-radius: 5px;
    }
    &::after {
      position: absolute;
      top: 1px;
      bottom: 1px;
      right: 1px;
      width: 15px;
      background-color: transparent;
      content: '';
      border-radius: 5px;
    }
    .p-inputtext {
      padding: 0;
      border: 0;
      box-shadow: none;
      min-height: 45px;
      height: auto;
      line-height: 45px;
      border-radius: var(--border-radius);
      padding: 0 35px 0 15px;
      border: 1px solid var(--border-gray);
      box-shadow: none !important;
      font-size: 16px;
      color: var(--black2);
      font-weight: 400;
      display: block;
      // white-space: nowrap;
      cursor: default;
      // overflow-x: auto;
      @include bp(sm-max) {
        font-size: 15px;
      }
      &::-webkit-scrollbar {
        height: 4px;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--gray-rgba);
        border-radius: 4px;
        .dark-theme & {
          background-color: var(--white-rgba);
        }
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--light-gray3);
        outline: none;
        border-radius: 4px;
      }
      .dark-theme & {
        border-color: var(--input-dark-text);
        color: var(--input-dark-text);
      }
      .p-autocomplete-input-token {
        width: 100%;
        padding: 0;
        border: 0;
        box-shadow: none;
        order: 1;
        input {
          width: 100%;
          box-shadow: none;
          height: inherit;
          line-height: 1;
          box-shadow: none;
          border: 0;
          .dark-theme & {
            color: var(--input-dark-text);
          }
        }
      }
    }
    .p-autocomplete-token {
      padding: 0 8px;
      height: 30px;
      line-height: 30px;
      color: var(--black2);
      background-color: var(--surface-100);
      font-size: 14px;
      .p-autocomplete-token-label {
        min-height: 10px;
      }
      .dark-theme & {
        color: var(--border-gray);
        background-color: var(--surface-body);
      }
    }
    &:focus {
      border-color: var(--primary-color);
    }
  }
  > textarea {
    + p {
      font-size: 12px;
    }
  }
}

.p-multiselect-panel {
  background-color: var(--surface-a);
  border-radius: $borderRadius;
  box-shadow: 0 3px 9px 1px rgba(58, 54, 68, 0.03), 0 9px 8px rgb(46, 43, 54, 0.02),
    0 1px 6px 4px rgb(48, 46, 57, 0.01);
  .dark-theme & {
    background-color: var(--dark-boxes);
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
  .p-multiselect-header {
    padding: 10px 15px;
    border: 0;
    .dark-theme & {
      background-color: var(--dark-boxes);
    }
    .p-multiselect-filter-container {
      .p-inputtext {
        height: 40px;
        line-height: 40px;
        border-radius: var(--border-radius);
        padding: 0 35px 0 15px;
        border: 1px solid var(--border-gray);
        box-shadow: none !important;
        font-size: 16px;
        color: var(--black2);
        font-weight: 400;
        @include bp(sm-max) {
          font-size: 15px;
        }
        .dark-theme & {
          color: var(--input-dark-text);
          border-color: var(--input-dark-text);
        }
        &:focus {
          border-color: var(--primary-color);
        }
      }
      .p-multiselect-filter-icon {
        color: var(--light-gray2);
        .dark-theme & {
          color: var(--input-dark-text);
        }
      }
    }
    .p-multiselect-close {
      color: var(--light-gray2);
      box-shadow: none !important;
      .dark-theme & {
        color: var(--input-dark-text) !important;
      }
    }
  }
  .p-multiselect-items-wrapper {
    ul {
      padding: 0;
    }
    .p-multiselect-empty-message {
      color: var(--input-dark-text);
    }
    .p-multiselect-item {
      font-size: 16px;
      box-shadow: none !important;
      padding: 15px;
      white-space: normal;
      word-break: break-all;
      max-width: 340px;
      @include bp(sm-max) {
        font-size: 15px;
      }
      @include bp(xxs-max) {
        max-width: inherit;
      }
      .dark-theme & {
        color: var(--input-dark-text);
      }
      &:not(.p-highlight) {
        &:hover {
          background-color: var(--surface-100) !important;
          .dark-theme & {
            background: var(--link-hover-bg);
          }
        }
      }
      &.p-highlight {
        color: var(--primary-color);
        .dark-theme & {
          background-color: var(--surface-body);
        }
      }
    }
  }
}

.p-autocomplete-panel {
  background-color: var(--surface-a);
  border-radius: $borderRadius;
  box-shadow: 0 3px 9px 1px rgba(58, 54, 68, 0.03), 0 9px 8px rgb(46, 43, 54, 0.02),
    0 1px 6px 4px rgb(48, 46, 57, 0.01);
  .dark-theme & {
    background-color: var(--dark-boxes);
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
  .p-autocomplete-items {
    padding: 0;
    .p-autocomplete-item {
      font-size: 16px;
      box-shadow: none !important;
      padding: 15px;
      @include bp(sm-max) {
        font-size: 15px;
      }
      .dark-theme & {
        color: var(--input-dark-text);
      }
      &:hover {
        background-color: var(--surface-100) !important;
        .dark-theme & {
          background: var(--link-hover-bg);
        }
      }
    }
  }
}

// radio buttons
.radio-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  .form-group-outer-radiobutton {
    display: inline-flex;
    align-items: center;
    label {
      padding: 0 0 0 8px;
      margin-bottom: 0;
      cursor: pointer;
      .dark-theme & {
        color: var(--input-dark-text);
      }
    }
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
}

.form-btn-wrapper {
  display: flex;
  margin: 24px -23px -10px;
  padding: 24px 23px 0;
  border-top: 1px solid var(--light-gray4);
  .dark-theme & {
    border-top-color: var(--border-light-color);
  }
  flex-wrap: wrap;
  @include bp(lgl-max) {
    margin: 20px -15px 0;
    padding: 20px 15px 0;
  }
  button {
    &:not(:last-child) {
      margin-right: 15px;
      @include bp(xxs-max) {
        margin-right: 0;
      }
    }
    margin-bottom: 10px;
    @include bp(xxs-max) {
      width: 100% !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

// Color picker css
.color-picker-wrapper {
  display: flex;
  position: relative;
  z-index: 1;
  .p-colorpicker {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    input {
      height: 25px !important;
      width: 25px;
      line-height: 25px !important;
      padding: 0;
      border: none !important;
    }
  }
  .hex-code {
    padding-left: 10px;
    font-size: 16px;
    color: var(--black2);
    font-weight: 400;
    @include bp(sm-max) {
      font-size: 15px;
    }
  }
  > input {
    padding-left: 60px;
  }
  &:before {
    position: absolute;
    content: '#';
    font-size: 20px;
    left: 1px;
    top: 1px;
    bottom: 1px;
    width: 48px;
    background-color: var(--light-gray4);
    border-radius: 6px 0 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black2);
    font-weight: 700;
    .dark-theme & {
      background-color: var(--surface-body);
      color: var(--input-dark-text);
    }
  }
}

// dropdown css
.form-col,
.custom-form-group {
  .p-dropdown {
    width: 100%;
    border-color: var(--border-gray);
    .dark-theme & {
      border-color: var(--input-dark-text);
    }
    &.p-inputwrapper-filled {
      .p-dropdown-trigger {
        color: var(--black2);
        .dark-theme & {
          color: var(--input-dark-text);
        }
      }
    }
    .p-dropdown-label {
      height: 43px;
      line-height: 43px;
      padding: 0 16px;
      color: var(--black2);
      .dark-theme & {
        color: var(--input-dark-text);
      }
    }
    .p-dropdown-trigger {
      color: var(--border-gray);
    }
    .p-dropdown-clear-icon {
      color: var(--border-gray);
    }
    .dark-theme & {
      background-color: transparent;
      color: var(--title-light-text);
      &:hover {
        border-color: var(--primary-color);
      }
      .p-dropdown-trigger {
        color: var(--input-dark-text);
      }
    }
  }
}

.p-dropdown-panel {
  &.custom-dropdown-panel {
    background-color: var(--surface-a);
    border-radius: $borderRadius;
    box-shadow: 0 3px 9px 1px rgba(58, 54, 68, 0.03), 0 9px 8px rgb(46, 43, 54, 0.02),
      0 1px 6px 4px rgb(48, 46, 57, 0.01);
    .dark-theme & {
      background-color: var(--dark-boxes);
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    }
    .p-dropdown-items {
      .p-dropdown-item {
        font-size: 16px;
        box-shadow: none !important;
        padding: 15px;
        color: #495057 !important;
        @include bp(sm-max) {
          font-size: 15px;
        }
        .dark-theme & {
          color: var(--input-dark-text) !important;
        }
        &:not(.p-highlight) {
          &:hover {
            background: #e9ecef;
            background-color: var(--surface-100) !important;
            .dark-theme & {
              background: var(--link-hover-bg);
            }
          }
        }
        &.p-highlight {
          color: var(--primary-color) !important;
          .dark-theme & {
            background-color: var(--surface-body);
          }
        }
      }
    }
    .p-dropdown-filter {
      height: 45px;
      line-height: 45px;
      border-radius: var(--border-radius);
      padding: 0 15px;
      border: 1px solid var(--border-gray);
      border-width: 1px !important;
      outline: 0;
      box-shadow: none;
      font-size: 16px;
      color: var(--black2);
      font-weight: 400;
      @include bp(sm-max) {
        font-size: 15px;
      }
      .dark-theme & {
        color: var(--input-dark-text);
      }
      &:focus {
        border-color: var(--primary-color) !important;
        &::placeholder {
          opacity: 0 !important;
        }
        &::-webkit-input-placeholder {
          opacity: 0 !important;
        }
        &:-moz-placeholder {
          opacity: 0 !important;
        }
        &::-moz-placeholder {
          opacity: 0 !important;
        }
        &:-ms-input-placeholder {
          opacity: 0 !important;
        }
      }
      &::placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;
        transition: all 0.15s;
        .dark-theme & {
          color: var(--title-light-text);
        }
      }
      &::-webkit-input-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;
        .dark-theme & {
          color: var(--title-light-text);
        }
      }
      &:-moz-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;
        .dark-theme & {
          color: var(--title-light-text);
        }
      }
      &::-moz-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;
        .dark-theme & {
          color: var(--title-light-text);
        }
      }
      &:-ms-input-placeholder {
        color: var(--light-gray2);
        opacity: 1 !important;
        .dark-theme & {
          color: var(--title-light-text);
        }
      }
    }
  }
}

.form-col {
  .p-dropdown {
    .dark-theme & {
      background-color: transparent;
      color: var(--title-light-text);
    }
  }
  &.has-custom-upload {
    label {
      margin-bottom: 8px;
      display: inline-block;
    }
  }
}

// Datepicker Css
body {
  .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
    z-index: 2103;
  }
}

.custom-datepicker {
  .MuiInputBase-formControl {
    border: 0;
    padding: 0;
    &::after {
      position: absolute;
      top: 0;
      height: 100%;
      right: 0;
      width: 40px;
      background-image: url(../../images/calendar.svg);
      background-repeat: no-repeat;
      background-size: 18px;
      content: '';
      background-position: center;
      .dark-theme & {
        background-image: url(../../images/calendar-dark-theme.svg);
      }
    }
    input {
      padding: 0 50px 0 15px !important;
      box-sizing: border-box;
    }
  }
  fieldset {
    border: 0;
    display: none;
  }
  .MuiInputAdornment-root {
    margin: 0;
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    top: 0;
    height: 100%;
    max-height: inherit;
    z-index: 5;
    .MuiButtonBase-root {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      z-index: 4;
      margin: 0;
      border-radius: 0;
      svg {
        fill: var(--border-gray);
        .dark-theme & {
          fill: var(--input-dark-text);
        }
      }
    }
  }
}

// Upload Document Css
.upload-document-wrapper {
  button {
    margin-right: 15px;
  }
  h5 {
    font-size: 13px;
    color: var(--primary-color);
    margin: 8px 0 !important;
  }
}

// datepicker css
.custom-datepicker-panel {
  .MuiPaper-root {
    box-shadow: 0 3px 9px 1px rgba(58, 54, 68, 0.03), 0 9px 8px rgb(46, 43, 54, 0.02),
      0 1px 6px 4px rgb(48, 46, 57, 0.01);
  }
  .dark-theme & {
    .MuiPaper-root {
      background-color: var(--surface-body);
    }
  }
  .MuiCalendarPicker-root {
    .MuiPaper-root {
      box-shadow: 0 3px 9px 1px rgba(58, 54, 68, 0.03), 0 9px 8px rgb(46, 43, 54, 0.02),
        0 1px 6px 4px rgb(48, 46, 57, 0.01);
    }
    .MuiTouchRipple-root {
      display: none;
    }
    .MuiButtonBase-root,
    button {
      color: var(--black2);
      .dark-theme & {
        color: var(--title-light-text2);
      }
      &.Mui-disabled {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
      }
      &:not(.Mui-selected) {
        border-color: var(--black2);
        .dark-theme & {
          color: var(--title-light-text2);
          background-color: transparent;
        }
      }
      &:hover {
        background-color: var(--rgba1) !important;
        .dark-theme & {
          background-color: var(--rgba3) !important;
        }
      }
    }
    .Mui-selected {
      background-color: var(--primary-color) !important;
      color: var(--surface-a);
      &:hover {
        background-color: var(--primary-color) !important;
        color: var(--surface-a);
        .dark-theme & {
          background-color: var(--primary-color) !important;
          // color: var(--surface-a) !important;
          color: var(--title-light-text2);
        }
      }
    }
    svg {
      color: var(--black2);
      .dark-theme & {
        color: var(--title-light-text2);
      }
    }
    .MuiDayPicker-header {
      .MuiTypography-root {
        .dark-theme & {
          color: var(--title-light-text2);
        }
      }
    }
    .MuiPickersCalendarHeader-labelContainer {
      color: var(--black2);
      .dark-theme & {
        color: var(--title-light-text2);
      }
    }
    .MuiDayPicker-slideTransition {
      min-height: 230px;
    }
    .MuiPaper-root {
      .dark-theme & {
        background-color: var(--surface-body);
      }
    }
  }
}

// custom timepicker
.custom-timepicker {
  .MuiInputBase-formControl {
    border: 0;
    padding: 0;
    input {
      padding: 0 50px 0 15px !important;
      box-sizing: border-box;
    }
  }
  fieldset {
    border: 0;
  }
  .MuiInputAdornment-root {
    margin: 0;
    .MuiButtonBase-root {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      svg {
        fill: var(--border-gray);
        .dark-theme & {
          fill: var(--input-dark-text);
        }
      }
    }
  }
}

.custom-timepicker-panel {
  .MuiPaper-root {
    box-shadow: 0 3px 9px 1px rgba(58, 54, 68, 0.03), 0 9px 8px rgb(46, 43, 54, 0.02),
      0 1px 6px 4px rgb(48, 46, 57, 0.01);
    .dark-theme & {
      background-color: var(--surface-body);
    }
  }
  .MuiClock-wrapper {
    .MuiClockNumber-root {
      .dark-theme & {
        color: var(--title-light-text2);
      }
    }
  }
  .MuiClock-clock {
    .dark-theme & {
      background-color: var(--dark-boxes);
    }
  }
  .MuiPickersArrowSwitcher-button {
    .dark-theme & {
      color: var(--title-light-text2);
    }
    &.Mui-disabled {
      opacity: 0.6;
    }
  }
  .MuiClock-root {
    .MuiClockPointer-root {
      background-color: var(--primary-color);
    }
    .MuiClockPointer-thumb {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
    .MuiClock-pin {
      background-color: var(--primary-color);
    }
    .MuiButtonBase-root {
      height: 35px;
      width: 35px;
      .MuiTypography-root {
        line-height: 0.98;
      }
      .dark-theme & {
        color: var(--title-light-text2);
      }
      // &.MuiClock-amButton{
      //     background-color: var(--primary-color);
      // }
    }
  }
}

.add-candidate-lpa {
  background-color: inherit;
  .dark-theme & {
    color: var(--input-dark-text) !important;
    border-color: var(--input-dark-text) !important;
  }
}
