.p-datatable-wrapper {
  min-height: calc(100vh - 295px);
  @include bp(sm-max) {
    min-height: inherit;
  }
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  .text-no-wrap {
    white-space: nowrap;
  }
  .text-bold {
    font-weight: 600;
  }
  .name-col {
    min-width: 150px;
    min-height: 40px;
  }
}
.table-header {
  .ml-auto {
    display: flex;
    align-items: center;
  }
  .ml-auto i {
    font-size: 1.1rem;
    cursor: pointer;
  }
}
.p-datatable-table {
  table-layout: fixed;
  tr td:last-child {
    width: 1px;
    white-space: nowrap;
  }
  tr td i {
    font-size: 1.1rem;
    cursor: pointer;
  }
  .p-datatable-header {
    background: #ffffff;
  }
}
.p-paginator {
  padding: 11px 16px 11px 22px;
  justify-content: flex-start;
  .dark-theme & {
    background-color: var(--dark-boxes);
    border-color: var(--dark-boxes);
  }
  @include bp(sm-max) {
    flex-wrap: wrap;
    padding: 10px 15px;
    .p-paginator-current {
      width: 100%;
      line-height: 1.3;
      margin-bottom: 5px;
    }
  }
  .p-paginator-current {
    line-height: 1.2;
  }
  .p-paginator-first,
  .p-paginator-pages .p-paginator-page,
  .p-paginator-prev,
  .p-paginator-page,
  .p-paginator-next,
  .p-paginator-last {
    min-width: 30px;
    height: 30px;
    border-radius: 5px;
    color: var(--text-color);
    font-size: 14px;
    margin: 0 3px;
    .dark-theme & {
      color: var(--title-dark-text);
      background-color: var(--surface-body);
      &.p-highlight {
        background-color: rgba(115, 103, 240, 0.4);
        &:hover {
          background-color: rgba(115, 103, 240, 0.2) !important;
        }
        &:focus {
          background-color: rgba(115, 103, 240, 0.4) !important;
        }
      }
      &:hover {
        color: var(--light-gray4) !important;
        background-color: darken(#25293c, 5) !important;
      }
    }

    &.p-disabled {
      background-color: var(--surface-g);
      color: var(--text-color);
      .dark-theme & {
        color: darken(#e8e8e8, 5) !important;
        background-color: darken(#2f3349, 5) !important;
      }
    }
  }
  .p-dropdown {
    height: 30px;
    align-items: center;
    margin-right: auto;
    border-color: #cac9cc;
    border-radius: 5px;
    .dark-theme & {
      background-color: var(--dark-boxes);
      border-color: var(--title-light-text);
    }

    @include bp(sm-max) {
      margin-left: 0;
    }
    &.p-inputwrapper {
      .p-dropdown-label {
        &.p-inputtext {
          padding: 0 0 0 10px;
          line-height: 30px;
          height: 30px;
          font-size: 14px;
          color: var(--light-gray3);
          .dark-theme & {
            color: var(--title-light-text);
          }
        }
      }
    }
    .p-dropdown-trigger {
      width: 2rem;
      color: var(--light-gray3);
      .dark-theme & {
        color: var(--title-light-text);
      }
      .pi {
        font-size: 0.7rem;
      }
    }
  }
}

.p-datatable-header {
  .dark-theme & {
    background-color: var(--dark-boxes) !important;
  }
  .table-header {
    flex-wrap: wrap;

    .table-actions-left {
      display: flex;
      align-items: center;
      margin: 0;
      padding-right: 10px;
      @include bp(lg-max) {
      }
      @include bp(sm-max) {
        // margin: 15px 0 0;
        padding-right: 0;
      }
      @include bp(sm-max) {
        margin-top: 15px;
      }
      @include bp(xs-max) {
        flex-wrap: wrap;
      }
      .input-outer-wrapper {
        &:not(:empty) {
          margin-right: 15px;
          @include bp(lg-max) {
            margin-right: 10px;
          }
          // @include bp(sm-max){
          //   flex-grow: 1;
          // }
          @include bp(xs-max) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        .search-input {
          @include bp(lg-max) {
            width: 100%;
          }
        }
      }
      // margin-right: 24px;
      @include bp(sm-max) {
        order: 2;
        width: 100%;
        margin-right: 0;
      }
    }
    .experienceFilter {
      width: auto !important;
      min-width: auto !important;
    }
    .table-actions-dropdowns {
      margin-bottom: 10px;
      // flex: 0 0 100%;
      // max-width: 100%;
      // @include bp(lg-max) {
      //   flex: 0 0 100%;
      //   max-width: 100%;
      //   justify-content: space-between;
      //   margin-top: 15px;
      // }
      @include bp(xs-max) {
        width: 100%;
      }
      @include bp(xxs-max) {
        margin-bottom: 0;
      }
      @include bp(xxxl-min) {
        margin-bottom: 0;
      }
      @include bp(xxs-max) {
        margin-top: 0;
      }
      .dropdowns-inner-wrapper {
        // max-width: 680px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // @include bp(lg-max){
        //   justify-content: space-between;
        // }
        @include bp(xs-max) {
          // margin-top: 15px;
          justify-content: space-between;
        }
        @include bp(sm-max) {
          // margin-top: 15px;
          justify-content: space-between;
        }
        @include bp(xxs-max) {
          flex-wrap: wrap;
          margin-top: 0;
        }
      }

      // @include bp(smd-max) {
      //   flex: 0 0 100%;
      //   max-width: 100%;
      // }
      @include bp(sm-max) {
        order: 3;
        min-width: auto;
      }

      .custom-form-group {
        // flex: 0 0 calc(50% - 12px);
        // max-width: calc(50% - 12px);
        min-width: 185px;
        @include bp(lg-max) {
          width: 153px;
          max-width: 153px;
          min-width: auto;
        }
        // @include bp(lg-max){
        //   flex: 0 0 calc(50% - 12px);
        //   max-width: calc(50% - 12px);
        // }
        .p-dropdown-label {
          @include bp(lg-max) {
            padding-right: 5px;
            padding-left: 10px;
          }
        }
        .p-dropdown-trigger {
          @include bp(lg-max) {
            width: 32px;
          }
        }
        &:not(:empty) {
          margin-right: 15px;

          @include bp(lg-max) {
            margin-right: 10px;
          }
          @include bp(xs-max) {
            margin-right: 0;
          }
        }
        &:empty {
          margin: 0 !important;
        }
        &:last-child {
          margin-right: 0;
        }
        // @include bp(smd-max) {
        //   flex: 0 0 calc(50% - 6px);
        //   max-width: calc(50% - 6px);
        //   &:not(:empty) {
        //     margin-top: 12px;
        //   }
        // }
        @include bp(xs-max) {
          flex: 0 0 calc(50% - 12px);
          max-width: calc(50% - 12px);
        }
        @include bp(xxs-max) {
          flex: 0 0 100%;
          max-width: 100%;
          &:not(:empty) {
            margin-top: 15px;
          }
        }
      }
    }
    @include bp(sm-max) {
      flex-wrap: wrap;
    }
    .p-input-icon-left {
      @include bp(sm-max) {
        order: 2;
        display: block;
        width: 100%;
      }

      &.search-input {
        .pi {
          margin-top: -1px;
          transform: translateY(-50%);
          color: var(--text-color);
          font-size: 18px;
          .dark-theme & {
            color: var(--title-light-text);
          }
        }
        .p-inputtext {
          width: 355px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          padding: 0 16px 0 39px;
          // transition: all 0.5s;
          @include bp(sm-max) {
            font-size: 15px;
          }
          .dark-theme & {
            color: var(--title-light-text);
            border-color: var(--title-light-text);
            &:focus {
              border-color: var(--primary-color);
            }
            &:hover {
              border-color: var(--primary-color);
            }
          }
          &:enabled {
            &:focus {
              border-width: 1px;
            }
          }

          &::-webkit-input-placeholder {
            color: var(--text-color);
            opacity: 1 !important;
            .dark-theme & {
              color: var(--title-light-text);
            }
          }

          &:-moz-placeholder {
            color: var(--text-color);
            opacity: 1 !important;
            .dark-theme & {
              color: var(--title-light-text);
            }
          }

          &::-moz-placeholder {
            color: var(--text-color);
            opacity: 1 !important;
            .dark-theme & {
              color: var(--title-light-text);
            }
          }

          &:-ms-input-placeholder {
            color: var(--text-color);
            opacity: 1 !important;
            .dark-theme & {
              color: var(--title-light-text);
            }
          }
          &::-webkit-search-decoration {
            display: none;
          }
          &::-webkit-search-cancel-button {
            display: none;
          }

          &::-webkit-search-results-button {
            display: none;
          }
          &::-webkit-search-results-decoration {
            display: none;
          }
          @include bp(xxlg-max) {
            width: 270px;
          }
          @include bp(lg-max) {
            width: 180px;
          }
          @include bp(md-max) {
            width: 170px;
          }
          @include bp(sm-max) {
            width: 100%;
          }
        }
      }
    }
    .table-actions-right {
      @include bp(md-max) {
        @include bp(smd-min) {
          margin-top: 15px;
          width: 100%;
          order: 1;
        }
      }
      @include bp(sm-max) {
        order: 1;
        width: 100%;
        text-align: right;
      }
      button {
        font-size: 14px;
        height: 45px;
        line-height: 40px;
        transition: all 0.5s;
        text-transform: uppercase;
        padding: 5px 15px;
        border-radius: 5px;
        @include bp(xxs-max) {
          font-size: 12px;
          // width: 100%;
        }
        &:focus {
          box-shadow: none !important;
        }
        &:not(:last-child) {
          margin-right: 15px;
          @include bp(lg-max) {
            margin-right: 8px;
          }
          // @include bp(smd-max) {
          //   margin-right: 12px;
          // }
          @include bp(xxs-max) {
            margin-right: 15px;
            // margin-bottom: 15px;
          }
        }
        &.export-btn {
          background-color: var(--white);
          border-color: var(--light-gray1);
          color: var(--heading-text);
          .pi {
            color: var(--heading-text);
            margin-right: 12px;
            transition: all 0.2s;
            @include bp(lg-max) {
              margin-right: 7px;
            }
            @include bp(sm-max) {
              margin-right: 3px;
              font-size: 14px;
            }
          }
          &:hover {
            background-color: var(--secondary-btn-bg);
            // background-color: darken(#f4f4f4, 5);
            // background-color: var(--title-light-text);
          }
          .dark-theme & {
            color: var(--light-gray1);
            .pi {
              color: var(--light-gray1);
            }
            &:hover {
              background-color: var(--secondary-btn-bg);
              color: var(--surface-a);
              border-color: var(--secondary-btn-bg);
              .pi {
                color: var(--surface-a);
              }
            }
          }
        }
        &.add-user-btn {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
          color: var(--primary-color-text);
          font-weight: 500;
          width: 45px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: darken(#7367f0, 5);
            border-color: darken(#7367f0, 5);
            .pi {
              color: var(--surface-a);
            }
          }
          .pi {
            color: var(--primary-color-text);
            margin-right: 0;
            height: 17px;
            width: 17px;
            @include bp(sm-max) {
              // font-size: 14px;
            }
          }
        }
      }
    }
  }
}
// i.delete-button,
// i.edit-button,
// i.view-button {
//   display: none;
// }
// .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td i.pi.pi-fw.pi-pencil {
//   display: none;
// }
.p-datatable-table {
  width: 100%;
  tr {
    td {
      @include bp(sm-max) {
        font-size: 14px;
      }
      .pi-file-pdf {
        font-size: 22px;
        color: var(--primary-color);
      }
      .pi-download {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: var(--primary-color);
      }
      .p-inputswitch {
        height: 24px;
        width: 40px;
        .p-inputswitch-slider {
          &::before {
            height: 16px;
            width: 16px;
            transform: translateY(-50%);
            margin: 0;
            left: 5px;
          }
        }
        &.p-inputswitch-checked {
          .p-inputswitch-slider {
            &::before {
              left: auto;
              right: 5px;
            }
          }
        }
      }
      .primary-icon-btn {
        &.action-popup-button {
          &:focus {
            background-color: var(--white2);
          }
        }
      }

      .test-color-pallette {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .color-badge {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-color: #cac9cc;
          display: block;
          margin-right: 8px;
        }
      }
    }
  }
}

.popup_menu {
  &.action-popup {
    min-width: 140px;
    max-width: 200px;
    width: auto;
    border-radius: 6px;
    padding: 10px 0;

    .p-menuitem {
      padding: 0 8px;
      .p-menuitem-link[disabled] {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
      }
      .p-menuitem-link {
        padding: 9px 8px 9px 8px;
        border-radius: 0;
        display: flex;
        align-items: center;
        border-radius: 6px;
        transition: all 0.5s;
        .p-menuitem-icon {
          font-size: 16px;
          margin-right: 11px;
        }
        &:hover {
          color: var(--black1);
          background-color: var(--white2);
          .p-menuitem-icon {
            color: var(--black1);
          }
          .dark-theme & {
            color: var(--title-dark-text);
            background-color: var(--link-hover-bg);
            .p-menuitem-icon {
              color: var(--title-dark-text);
            }
          }
        }
      }
    }
  }
}
.more-actions-wrapper {
  margin-left: 10px;
  .p-button {
    &.primary-icon-btn {
      &.p-button-rounded {
        min-width: 32px;
        height: 32px;
        width: 32px;
        .pi {
          font-size: 18px;
        }
      }
    }
  }
}
.__react_component_tooltip.place-top::before {
  display: none !important;
}

.technology-list {
  margin: 0 -15px -8px 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  @include bp(lmd-max) {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .technology-badge {
    padding: 0px 8px;
    height: 22px;
    line-height: 22px;
    background-color: transparent;
    border-radius: 3px;
    margin: 0 6px 6px 0;
    // min-width: 80px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    // border-width: 0px;
    // border-style: solid;
    // border-color: transparent;
    border: none;
    color: var(--surface-a) !important;
    &.java-badge {
      /* done */
      background: #4dd0e1 !important;
    }
    &.net-badge {
      /* done */
      background: #7986cb !important;
    }
    &.ios-badge {
      background: #9575cd !important;
    }
    &.reactjs-badge {
      /* done */
      background: #4fc3f7 !important;
    }
    &.react-badge {
      /* done */
      background: #4fc3f7 !important;
    }
    &.node-badge {
      /*done*/
      background: #4db6ac !important;
    }
    &.nodejs-badge {
      /*done*/
      background: #4db6ac !important;
    }
    &.designing-badge {
      /*done*/
      background: #aed581 !important;
    }
    &.android-badge {
      /*done*/
      background: #90a4ae !important;
    }
    &.php-badge {
      /*done*/
      background: #ba68c8 !important;
    }
    &.laravel-badge {
      /*done*/
      background: #e57373 !important;
    }
    &.vuejs-badge {
      /*done*/
      background: #f06292 !important;
    }
    &.perl-badge {
      /*done*/
      background-color: #ffd54f !important;
    }
    &.angular-badge {
      /*done*/
      background-color: #81c784 !important;
    }
    &.python-badge {
      /*done*/
      background-color: #ffb74d !important;
    }
    &.go-badge {
      /*done*/
      background-color: #64b5f6 !important;
    }
    &.c-badge {
      background-color: #ff8a65 !important;
    }
    &.express-badge {
      background-color: #aed581 !important;
    }
    &.expressjs-badge {
      background-color: #aed581 !important;
    }
  }
}
.table-view-popup-link {
  &:hover {
    text-decoration: underline;
  }
}
