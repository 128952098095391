.astrict-sign {
  color: var(--astrict-color);
}

.authentication-wrapper {
  padding: 20px 150px 40px 40px;
  background-color: var(--surface-a);
  background-image: url('../../images/login-bg.jpg');
  background-position: center bottom;
  background-size: cover;
  @include bp(llg-max) {
    padding-right: 100px;
  }
  @include bp(lg-max) {
    padding: 20px 40px 40px;
  }
  @include bp(sm-max) {
    padding: 20px 15px 40px;
  }
  .dark-theme & {
    background-color: var(--surface-body);
  }
  .tatva-logo-wrap{
    max-width: 150px;    
    width: 100%;
    margin: 0 0 5px;
    img{
      display: block;
    }
  }
  .authentication-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    min-height: calc(100vh - 102px);
    @include bp(lg-max) {
      justify-content: center;
    }
    .login-container {
      max-width: 470px;
      width: 100%;
      background-color: var(--surface-a);
      padding: 50px;
      border-radius: 25px;
      box-shadow: 0 0px 30px 100px rgb(255 255 255 / 15%);
      @include bp(sm-max) {
        padding: 25px;
      }
      .dark-theme & {
        background-color: var(--dark-boxes);
      }
      .site-logo {
        margin: 0 auto 40px;
        display: block;
        text-align: center;
        @include bp(sm-max) {
          margin-bottom: 24px;
        }
        img {
          width: 216px;
          margin: 0 auto;
          @include bp(sm-max) {
            width: 200px;
          }
          .dark-theme & {
            display: none;
          }
          &.dark-theme-logo {
            display: none;
            .dark-theme & {
              display: block;
            }
          }
        }
      }
      h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: var(--heading-text);
        margin: 0 0 10px;
        text-align: center;
        @include bp(lg-max) {
          font-size: 22px;
        }
        @include bp(sm-max) {
          font-size: 20px;
          margin-bottom: 8px;
        }
        .dark-theme & {
          color: var(--title-dark-text);
        }
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--text-color);
        margin-bottom: 25px;
        text-align: center;
        @include bp(sm-max) {
          font-size: 14px;
          margin-bottom: 18px;
        }
        .dark-theme & {
          color: var(--title-light-text2);
        }
      }
      form {
        .field {
          &.pwd {
            margin-bottom: 8px;
          }
          .p-inputtext {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            height: 45px;
            padding-right: 41px;
            @include bp(sm-max) {
              font-size: 15px;
            }
            .dark-theme & {
              color: var(--input-dark-text);
              border-color: var(--input-dark-text);
            }
            &.p-invalid {
              &:focus {
                border-color: var(--primary-color);
              }
            }
          }
          .p-password.p-invalid.p-component > .p-inputtext {
            &:focus {
              border-color: var(--primary-color);
            }
          }
          .p-input-icon-right {
            .pi {
              color: var(--black2);
              top: 44%;
              font-size: 18px;
              right: 16px;
            }
          }
          > label span {
            font-weight: 300;
            font-size: 13px;
            line-height: 15px;
            color: var(--heading-text);
            margin-bottom: 5px;
            .dark-theme & {
              color: var(--title-light-text2);
            }
            &.input-focus {
              color: var(--focus-label-color);
            }
          }
        }
        .link-wrapper {
          margin-bottom: 16px;
          text-align: right;

          &.back-login {
            text-align: center;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              .pi-angle-left {
                font-size: 20px;
                padding-right: 3px;
                @include bp(sm-max) {
                  font-size: 18px;
                }
              }
            }
          }
          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: var(--primary-color);
          }
        }
        button {
          margin: 8px 0 16px;
          font-family: 'Public Sans', sans-serif;
          &.Mui-disabled {
            color: var(--surface-a);
          }
        }
      }
    }
  }
  &.forgot-pwd {
    .authentication-inner {
      .right-col {
        button {
          margin-bottom: 30px;
        }
      }
    }
  }
  &.reset-pwd {
    .authentication-inner {
      .right-col {
        h2 {
          margin-bottom: 18px;
        }
      }
    }
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.keep-me-sign-in {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
  }
}
