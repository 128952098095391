.p-dialog-mask {
  padding: 10px;
}
.p-confirm-dialog{
  max-width:35%;
  @include bp(smd-max){
    max-width: 60%;
  }
}
.p-dialog {
  border-radius: 15px;
  background-color: var(--surface-a);
  margin: 0;
  padding: 0;
  max-height: 100%;
  .dark-theme & {
    background-color: var(--dark-boxes);
    color: var(--title-dark-text);
  }
  .p-dialog-header {
    padding: 20px 24px;
    border-radius: 0;
    background-color: transparent;
    @include bp(smd-max) {
      padding: 15px;
    }
    .p-dialog-title {
      font-size: 18px;
      line-height: 1.22;
      font-family: 'Public Sans', sans-serif;
      font-weight: 600;
      color: var(--heading-text);
      .dark-theme & {
        color: var(--title-dark-text);
      }
    }
    .p-dialog-header-icons {
      .p-dialog-header-close {
        height: 30px;
        width: 30px;
        margin-left: 10px;
        background-color: var(--light-gray) !important;
        .dark-theme & {
          background-color: var(--border-light-color) !important;
        }
        span {
          font-weight: 700;
          color: var(--text-color);
          .dark-theme &{
            color: var(--title-dark-text);
          }
          &:hover {
            color: var(--text-color);
            .dark-theme &{
              color: var(--title-dark-text);
            }
          }
        }
      }
      .p-dialog-header-icon {
        .dark-theme & {
          color: var(--title-dark-text);
        }
      }
    }
  }
  .p-dialog-content {
    background-color: transparent;
    color: var(--heading-text);
    padding: 3px 24px 24px;
    @include bp(smd-max) {
      padding: 3px 15px 15px;
    }
    .dark-theme & {
      color: var(--title-dark-text2);
    }
    .pi-exclamation-triangle, .pi-question-circle {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .p-confirm-dialog-message {
      font-size: 16px;
      line-height: 1.60;
      margin-left: 10px;
      margin-bottom: 20px;
    }
  }
  .p-dialog-footer {
    border-top: 1px solid var(--light-gray4);
    background-color: transparent;
    padding: 15px 24px;
    .dark-theme &{
      border-color: var(--border-light-color);
    }
    @include bp(smd-max) {
      padding: 15px;
    }
  }
  .p-button {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--primary-color-text);
    text-transform: uppercase;
    height: 45px;
    line-height: 20px;
    padding: 5px 15px;
    box-shadow: none !important;
    transition: all 0.5s;
    font-family: 'Public Sans', sans-serif;
    margin: 0 0 0 12px;
    font-size: 14px;
    letter-spacing: 0.02857em;
    min-width: 64px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include bp(xxs-max) {
      font-size: 12px;
    }
    .p-button-label {
      font-weight: 400;
      transition: all 0.5s;
      flex: none;
    }
    &:first-child {
      margin: 0;
    }
    &:hover {
      background-color: darken(#7367f0, 5);
      border-color: darken(#7367f0, 5);
    }
    &.p-button-text {
      background-color: var(--white);
      border: 1px solid var(--light-gray1);
      color: var(--heading-text);
      &:hover {
        background-color: var(--secondary-btn-bg);
        border-color: var(--light-gray1);
        color: var(--heading-text);
      }
      .dark-theme & {
        color: var(--primary-color-text);
      }
    }
  }
  .p-tabview {
    .p-tabview-nav-container{
      margin: 0 -24px;
      border-bottom:1px solid var(--light-gray4);
      .dark-theme &{
        border-color: var(--border-light-color);
      }
      @include bp(smd-max){
        margin: 0 -15px;
      }
      .p-tabview-nav-content{
        position: relative;
      }
    }
    .p-tabview-nav {      
      border: none;
      li {
        .p-tabview-nav-link {
          padding: 13px 24px;
          font-size: 16px;
          line-height: 1.18;
          font-family: 'Public Sans', sans-serif;
          font-weight: 400;
          color: var(--heading-text);
          border: none;
          margin: 0;
          .dark-theme & {
            color: var(--title-dark-text);
          }
          @include bp(smd-max){
            padding: 13px 15px;
          }
        }
        &.p-highlight {
          .p-tabview-nav-link {
            color: var(--primary-color);
          }
        }
      }
    }
    .p-tabview-panels {
      padding: 37px 0;
    }
  }
  .detail-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 21px;
    &:last-child{
      margin: 0;
    }
    .title {
      max-width: 230px;
      flex: 0 0 220px;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.18;
      font-family: 'Public Sans', sans-serif;
      display: inline-flex;
      justify-content: space-between;
      color: var(--heading-text);
      .dark-theme & {
        color: var(--title-dark-text);
      }
      @include bp(sm-max){
        font-size: 15px;
      }
      @include bp(xs-max){
        max-width: 120px;
        flex: 0 0 120px;
      }
      @include bp(xxxs-max) {
        flex: 0 0 110px;
        max-width: 110px;
      }
      .colon {
        padding: 0 12px;
      }
    }
    .content {
      flex: 1;
      margin: 0;
      font-size: 16px;
      line-height: 1.18;
      font-family: 'Public Sans', sans-serif;
      color: var(--heading-text);
      word-break: break-all;
      @include bp(sm-max){
        font-size: 15px;
      }
      @include bp(xxxs-max) {
        word-break: break-word;
      }
      .dark-theme & {
        color: var(--title-dark-text);
      }
      .feedback-slider-wrap {
        max-width: 400px;
        width: 100%;
        margin: 0 0 15px;
        .slider-title {
          display: block;
          padding: 0 0 5px;
        }
        .p-progressbar {
          height: 16px;
          background-color: #c5bfff;
          border-radius: 15px;
          .p-progressbar-label {
            font-size: 12px;
            line-height: 16px;
            color: var(--surface-a);
          }
        }
      }
    }
  }
}
.interview-details-dialog {
  width: 100%;
  max-width: 718px;
  .content-wrap {
    min-height: 200px;
  }
}
// Feedback popup css
.feedback-dialog {
  max-width: 600px;
  width: 100%;
}
.form-box-wrapper {
  .form-row-wrapper {
    .form-col {
      p {
        color: var(--light-gray2);
        font-size: 12px;
        line-height: 1.18;
        font-family: 'Public Sans', sans-serif;
        padding: 5px 0 0;
      }
    }
  }
  &.feedback-form-popup {
    .form-row-wrapper {
      padding: 0 0 24px;
      @include bp(smd-max) {
        padding: 0 0 15px;
      }
    }
    .p-dialog-footer {
      margin: 0 -24px -24px;
      @include bp(smd-max) {
        margin: 0 -15px -15px;
      }
    }
    .p-slider {
      background-color: #c5bfff;
      border-radius: 5px;
      height: 5px;
      margin-bottom: 8px;
      .p-slider-range {
        border-radius: 5px;
        background-color: var(--primary-color);
      }
      .p-slider-handle {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        border: 4px solid #fff;
        height: 24px;
        width: 24px;
        background-color: var(--primary-color);
        transform: translate(-50%, -50%);
        margin: 0;
        .dark-theme & {
          border-color: var(--title-dark-text);
        }
      }
    }
  }
}
// person details popup
.person-details-popup {
  max-width: 520px;
  width: 100%;
}

// log-detail-popup
.log-detail-popup{
  .p-datatable.p-datatable-sm{
    .p-datatable-header{
      padding-top: 5px;
      padding-left: 0;
    }
  }
}

// availability-detail-popup
.availability-detail-popup{
  @include bp(sm-max){
    width: 98% !important;
    padding: 0 !important;
  }
  .p-dialog-content{
    .card{
      padding: 0;
      // @include bp(sm-max){
      //   padding: 0;
      // }
    }    
    .calendar-wrap{
      .fc-theme-standard{
        .fc-scrollgrid{
          @include bp(smd-max){
            min-width: 718px;
          }
        }
      }
    }
    .p-tabview{
      .p-tabview-panels{
        @include bp(sm-max){
          padding: 25px 0;
        }  
      }
      .p-tabview-nav-container{
        .p-tabview-nav-content{
          @include bp(sm-max){
            margin: 0;
          }
        }
      }
    }
  }
}
.notesWrap{
  .notesListWrap{
    overflow-x: auto;
  }
  .MuiList-root{
    .MuiListItem-root{
     padding: 5px 60px 5px 0;
      .MuiTypography-root{
        font-size: 14px;
      }
    }
  }
}

.dropdown_menu {
  .MuiPopover-paper {
    overflow: hidden;
    box-shadow: 1px 1px 3px 1px rgba(58, 54, 68, 0.03);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 10px;
    right: 195px;
    left: auto !important;
    @include bp(llg-max) {
      right: 165px;
    }
    @include bp(sm-max) {
      right: 57px;
    }
    @include bp(xs-max) {
      right: 30px;
    }
    @include bp(xxxs-max) {
      right: 15px;
    }
  }
  .MuiMenu-list {
    padding: 0 0 0px !important;
  }
  .heading {
    padding: 15px !important;
    border-bottom: 1px solid var(--surface-h);
    h2 {
      color: var(--heading-text);
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
      margin: 0;
    }
    .clear-all {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }
  .notification-modal {
    @include bp(xs-max) {
      max-width: 320px;
      min-width: 1px;  
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 5px;
      position: relative;
      right: 8px;
      padding-right: 8px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: var(--gray-400);
      opacity: 0.8;
      max-height: 100px;
    }
    li {
      padding: 10px 15px;
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
      &.unread {
        background: #f6f6ff;
      }
    }
    .user-info {
      .user-data {
        padding: 0;
      }
    }
  }
  .all-read {
    padding: 10px 15px;
    border-top: 1px solid #eee;
    a {
      text-decoration: none;
      color: inherit;
      font-size: 14px;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}