.error-page {
  padding: 20px;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .text-wrapper {
    text-align: center;
    h2 {
      font-size: 34px;
      line-height: 42px;
      color: var(--heading-text);
      margin: 0 0 12px;
      font-weight: 500;
      @include bp(sm-max) {
        font-size: 30px;
        line-height: 38px;
      }
      .dark-theme & {
        color: var(--title-dark-text);
      }
    }
    p {
      font-size: 16px;
      line-height: 24px;
      color: var(--text-color);
      margin: 0 0 16px;
      .dark-theme & {
        color: var(--title-light-text2);
      }
    }
    .go-back-btn {
      margin-bottom: 48px;
      min-width: 162px;
      font-weight: 400;
    }
  }
  .error-page-inner {
    padding-bottom: 450px;
    position: relative;
  }
  .error-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 200px;
    }
  }
  .error-bg-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    img {
      width: 100%;
    }
  }
}
