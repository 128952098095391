.notification {
  position: fixed;
  right: 24px;
  bottom: 66px;
  padding: 15px 24px;
  z-index: 2000;
  background-color: #ffe7c9;
  border: 1px solid #f88e13;
  border-radius: $borderRadius;
  .notification-title {
    color: #f88e13;
    font-size: 16px;
    line-height: 1.18;
    font-weight: 400;
  }
  .notification-close {
    color: #f88e13;
    font-size: 12px;
    font-weight: 600;
    padding: 2px;
    margin-left: 10px;
  }
}
