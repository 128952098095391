.white-box {
  background-color: var(--surface-a);
  border-radius: 10px;
  // box-shadow: 0 3px 9px 1px rgba(58, 54, 68, 0.03), 0 9px 8px rgb(46, 43, 54, 0.02),
  //   0 1px 6px 4px rgb(48, 46, 57, 0.01);
  box-shadow: 1px 1px 3px 1px rgba(58, 54, 68, 0.03);
  height: 100%;
  padding: 20px;
  .dark-theme & {
    background-color: var(--dark-boxes);
  }
  @include bp(lgl-max) {
    padding: 15px;
  }
}

.white-box-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -24px;
  @include bp(smd-max) {
    margin: 0 -8px -16px;
  }
  .white-box-item {
    padding: 0 12px 24px;
    @include bp(smd-max) {
      padding: 0 8px 16px;
    }
    @include bp(ssm-max) {
      flex-wrap: wrap;
    }
    .auto-margin {
      .p-chart {
        margin: 0 auto;
        canvas {
          margin: 0 auto;
        }
      }
    }
    &.small-width {
      flex: 0 0 50%;
      max-width: 50%;
      @include bp(xlg-max) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .chart-figure-wrapper {
        .p-chart {
          display: flex;
          justify-content: center;
          canvas {
            width: 100%;
          }
        }
      }
    }
    &.half-width {
      flex: 0 0 50%;
      max-width: 50%;
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &.full-width {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .card-title {
      font-size: 20px;
      line-height: 1.16;
      margin-bottom: 30px;
      font-weight: 400;
      color: var(--heading-text);
      // opacity: 0.87;
      .dark-theme & {
        color: var(--title-dark-text);
        opacity: 1;
      }
      @include bp(lg-max) {
        font-size: 18px;
      }
      @include bp(sm-max) {
        font-size: 16px;
        margin-bottom: 18px;
      }
    }
    .card-title-block {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      flex: 1;
      margin-bottom: 30px;
      @include bp(sm-max) {
        margin-bottom: 15px;
      }
      .equal-height-JlocK {
        @include bp(sm-max) {
          height: inherit !important;
        }
        .custom-target-icon {
          vertical-align: middle;
        }
      }
      &.secondary-title {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .card-title {
        margin: 0;
      }
      .filter-block {
        padding-left: 5px;
        position: relative;
        .filter-btn {
          //   position: absolute;
          margin-top: -10px;
          @include bp(lg-max) {
            width: 30px;
            height: 30px;
            min-width: 30px;
            max-width: 30px;
            margin-top: -5px;
          }
          @include bp(sm-max) {
            margin-top: -7px;
          }
          i {
            // margin-top: 2px;
            height: 25px;
            width: 25px;
            display: inline-block;
            @include bp(lg-max) {
              height: 20px;
              width: 20px;
            }
            img {
              max-width: 100%;
              &.default-img {
                display: block;
                .dark-theme & {
                  display: none;
                }
              }
              &.dark-mode-img {
                display: none;
                .dark-theme & {
                  display: block;
                }
              }
            }
          }
        }
        .chartFilterMenu {
          top: 100% !important;
          left: auto !important;
          right: 0 !important;
        }
      }
    }
  }
  &.no-bottom-margin {
    margin-bottom: 0;
  }
}

.dashboard-card-list {
  margin: 0 -12px 0;
  @include bp(smd-max) {
    margin: 0 -8px 0;
  }
  @include bp(ssm-max) {
    .left-block-padding {
      padding-right: 0;
      padding-bottom: 12px;
    }

    .dashboard-card-lower-item {
      padding-top: 0 !important;
      padding-bottom: 12px !important;
    }
  }
  .dashboard-card-item {
    flex: 0 0 50%;
    max-width: 50%;
    @include bp(lg-max) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include bp(ssm-max) {
      flex: 0 0 100%;
      max-width: 100%;

      .left-block-padding {
        padding-right: 0;
        padding-bottom: 12px;
      }
    }
    .card-title-block {
      align-items: flex-start;
    }
    .card-content {
      display: flex;
      justify-content: space-between;
      color: var(--heading-text);
      .icon-block {
        margin: 0;
        i {
          height: 46px;
          width: 46px;
          border-radius: 100%;
          background-color: #9575cd;
          padding: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
          }
          .active-img {
            display: none;
          }
          &.candidate-ic {
            padding: 10px;
            background-color: #64b5f6;
          }
        }
      }
      .content {
        padding-right: 6px;
      }
      .card-title {
        margin: 0;
        font-size: 16px;
        line-height: 1.25;
        color: var(--text-color);
        .dark-theme & {
          color: var(--title-light-text2);
        }
      }
      .number {
        font-size: 24px;
        line-height: 1.2;
        color: var(--heading-text);
        margin: 0 0 4px;
        .dark-theme & {
          color: var(--title-dark-text);
        }
        @include bp(lg-max) {
          font-size: 18px;
        }
      }
      .status-block {
        display: flex;
        margin: 0 -8px;
        .status-title {
          display: flex;
          align-items: center;
          margin: 0 0 5px;
          color: var(--text-color);
        }
        .user-status {
          // flex: 0 0 50%;
          // max-width: 50%;
          padding: 0 8px;
          text-align: center;
          .status-title {
            font-size: 14px;
            i {
              height: 32px;
              width: 32px;
              padding: 3px;
              background-color: #4db6ac;
              color: var(--active-text);
              border-radius: $borderRadius;
              margin: 0;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .number {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            padding-left: 3px;
          }
          &.inactive-users {
            // border-left: 1px solid var(--light-gray);
            // .dark-theme & {
            //     border-color: var(--border-light-color);
            // }
            .status-title {
              i {
                background-color: var(--inactive-user-bg);
                color: var(--inactive-text);
              }
            }
          }
        }
      }
    }
    .card-title-block {
      margin: 0;
      .card-title {
        margin: 0;
      }
    }
    &.active-item {
      .white-box {
        background-color: var(--primary-color);
        .icon-block {
          i {
            background-color: var(--white-rgba);
            .active-img {
              display: block;
            }
            .default-img {
              display: none;
            }
          }
        }
        .card-title {
          color: var(--surface-a);
          opacity: 1;
        }
        .number {
          color: var(--surface-a);
        }
      }
    }
  }
}

.secondary-card-list {
  .left-block {
    flex: 0 0 25%;
    max-width: 25%;
    @include bp(xxlg-max) {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .dashboard-card-item {
      flex: 0 0 100%;
      max-width: 100%;
      height: 50%;
      @include bp(xxlg-max) {
        height: auto;
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include bp(sm-max) {
        flex: 0 0 50%;
        max-width: 50%;
        height: auto;
      }
      @include bp(ssm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .card-content {
        align-items: center;
        height: 100%;
      }
      .card-title-block {
        @include bp(xxlg-min) {
          flex-direction: column-reverse;
        }
        .icon-block {
          @include bp(xxlg-min) {
            margin: 0 0 12px;
          }
        }
        .content {
          @include bp(xxlg-min) {
            padding-left: 5px;
            width: 100%;
          }
        }
      }
    }
  }
  .right-block {
    flex: 0 0 75%;
    max-width: 75%;
    @include bp(xxlg-max) {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
    }
    .dashboard-card-item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

// interviewer-dashboard
.interviewer-dashboard-card {
  padding: 0;
  .white-box {
    border-radius: 0 0 10px 10px;
  }
  .interviewer-table-header {
    border-bottom: 1px solid #dee2e6;
    padding: 15px;
    h5 {
      font-weight: 400;
      color: var(--heading-text);
      @include bp(lg-max) {
        font-size: 18px;
      }
      @include bp(sm-max) {
        font-size: 16px;
      }
      .dark-theme & {
        color: var(--title-dark-text);
      }
    }
    .button-wrapper {
      @include bp(xxs-max) {
        width: 100%;
        margin-top: 8px;
        flex: unset !important;
      }
    }
    .dark-theme & {
      border-bottom-color: var(--border-light-color);
    }
    .p-badge {
      font-size: 15px;
      padding: 6px 13px;
      height: 30px;
      line-height: 20px;
    }
  }
  .custom-table-wrapper {
    .p-datatable-wrapper {
      min-height: calc(100vh - 580px);
    }
  }
}
.atlwdg-trigger {
  z-index: 1000;
}

.dashboard-card-item-height {
  height: 50% !important;
  padding: 0 !important;
}

.dashboard-card-upper-item {
  padding-bottom: 12px !important;
}

.dashboard-card-lower-item {
  padding-top: 12px !important;
}

.align-center {
  display: flex;
  align-items: center;
}

.full-width {
  width: 100%;
}

.highlighted-item .p-menuitem-link {
  color: #4338ca;
  background: #eef2ff;
}

.dark-theme .highlighted-item .p-menuitem-link {
  color: #373a3c !important;
  background: #eef2ff !important;
}

.dark-theme .highlighted-item .p-menuitem-link .p-menuitem-text {
  color: #373a3c !important;
}

.dark-theme .popup_menu.action-popup .p-menuitem .p-menuitem-link:hover {
  background: #eef2ff !important;
}

.dark-theme .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #373a3c !important;
}

.display-flex {
  display: flex;
}

.left-block-padding {
  padding-right: 12px;
}
