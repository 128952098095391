.fc .fc-col-header-cell-cushion {
  padding: 16px 0px;
  display: flex;
  justify-content: center;
}

.fc-event-main-frame {
  display: flex;
  flex-wrap: wrap;
  .fc-event-time {
    order: 2;
  }
}

.fc-popover-header {
  padding: 0.5rem !important;
}

.fc-more-popover {
  overflow-y: scroll;
  max-height: 40%;
  max-width: 40%;
  z-index: 1 !important;
}

.fc-daygrid-event-harness {
  margin: 2px 0px;
}

.fc-event {
  padding: 4px;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.fc-sticky {
  text-overflow: ellipsis;
}

.fc-view {
  height: 100%;
}

.fc-scroller {
  overflow: hidden !important;
}
