.layout-sidebar {
  position: fixed;
  width: 250px;
  height: 100vh;
  z-index: 990;
  user-select: none;
  top: 0;
  left: 0;
  transition: transform $transitionDuration, left $transitionDuration, width $transitionDuration;
  background-color: var(--surface-overlay);
  padding: 0 0 69px;
  .sidebar-hide:not(.sidebar-hover) & {
    @include bp(smd-min-2) {
      width: 80px;
      overflow: hidden;
    }
  }
  // box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
  //   0px 1px 4px rgba(0, 0, 0, 0.08);
  // border-top-right-radius: 35px;
  // border-bottom-right-radius: 35px;

  .dark-theme & {
    background-color: var(--dark-boxes);
  }
  .sidebar-inner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .layout-menu-container {
    height: 100%;
  }
  .copyright-block {
    position: absolute;
    bottom: 0;
    padding: 5px 14px 15px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    line-height: 1.17;
    color: var(--login-text2);
    .dark-theme & {
      color: var(--title-light-text2);
    }
    .site-name {
      font-size: 14px;
      margin-bottom: 10px;
      img {
        width: 160px;
        margin: 0 auto;
        cursor: pointer;
      }
      .sidebar-hide:not(.sidebar-hover) & {
        @include bp(smd-min-2) {
          font-size: 12px;
          width: 45px;
          overflow: hidden;
          transform: translateX(5px);
        }
      }
    }
    p {
      margin: 0 0 3px;
    }
  }
}

.site-logo-wrap {
  padding: 25px 14px 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sidebar-hide:not(.sidebar-hover) {
    @include bp(smd-min-2) {
      padding: 25px 14px 20px 15px;
    }
  }
  @include bp(llg-max) {
    padding: 20px 14px 15px 25px;
    .sidebar-hide:not(.sidebar-hover) {
      @include bp(smd-min-2) {
        padding: 20px 14px 15px 15px;
      }
    }
  }
  @include bp(smd-max) {
    display: none;
  }
  .site-logo {
    display: block;
    transition: transform $transitionDuration;
    .sidebar-hide:not(.sidebar-hover) & {
      @include bp(smd-min-2) {
        min-width: 24px;
        overflow: hidden;
        width: 24px;
        transform: translateX(-3px);
      }
    }
    img {
      margin: 0 auto;
    }
  }
  .sidebar-toggle {
    position: relative;
    line-height: 0;
    cursor: pointer;
    margin: -10px;
    padding: 10px;
    .sidebar-hide:not(.sidebar-hover) & {
      @include bp(smd-min-2) {
        display: none;
      }
    }
    .dark-theme & {
      filter: invert(1);
    }
    img {
      transition: all 0.5s;
      width: 18px;
      height: 18px;
    }
    .hide-dot {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s;
      .sidebar-hide & {
        opacity: 1;
      }
    }
    .show-dot {
      .sidebar-hide & {
        opacity: 0;
      }
    }
  }
}

.sidebar-graphic-block {
  .sidebar-hide:not(.sidebar-hover) & {
    @include bp(smd-min-2) {
      display: none;
    }
  }
  img {
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    display: block;
    @include bp(xxs-max) {
      max-width: 170px;
    }
  }
}
.p-dialog-content {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    right: 8px;
    padding-right: 8px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--gray-400);
    opacity: 0.8;
    max-height: 100px;
  }
}
.layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 0 25px;
  width: 100%;
  transition: padding $transitionDuration;
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    right: 8px;
    padding-right: 8px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--gray-400);
    opacity: 0.8;
    max-height: 100px;
  }
  @include bp(smd-max) {
    padding: 15px;
  }
  @include bp(xxs-max) {
    height: calc(100vh - 160px);
  }
  .sidebar-hide:not(.sidebar-hover) & {
    @include bp(smd-min-2) {
      padding: 0 15px;
    }
  }
  li {
    margin: 0 0 10px;
    &.layout-menuitem-category {
      margin-top: 0.75rem;
      &:first-child {
        margin-top: 0;
      }
    }
    .layout-menuitem-root-text {
      text-transform: uppercase;
      color: var(--surface-900);
      font-weight: 600;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      display: none;
    }
    a {
      cursor: pointer;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--heading-text);
      transition: color $transitionDuration;
      border-radius: 25px;
      padding: 11px 15px;
      transition: background-color 0.15s, border-radius $transitionDuration;
      width: 100%;
      height: 45px;
      .sidebar-hide:not(.sidebar-hover) & {
        @include bp(smd-min-2) {
          position: relative;
          width: 45px;
          padding: 11px 13px;
          text-align: center;
          &::after {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            z-index: -1;
          }
        }
      }
      .pi {
        width: 20px;
      }
      .dark-theme & {
        color: var(--title-light-text2);
      }
      span {
        margin-left: 0.5rem;
        display: inline-block;
        line-height: 1;
        .sidebar-hide:not(.sidebar-hover) & {
          @include bp(smd-min-2) {
            display: none;
          }
        }
      }
      .menuitem-toggle-icon {
        margin-left: auto;
      }
      &:focus {
        @include focused-inset();
      }
      &:hover {
        background: var(--rgba1);
        .sidebar-hide:not(.sidebar-hover) & {
          @include bp(smd-min-2) {
            background-color: transparent;
            &::after {
              background: var(--rgba1);
            }
          }
        }
        .dark-theme & {
          background: var(--link-hover-bg);
          .sidebar-hide:not(.sidebar-hover) & {
            @include bp(smd-min-2) {
              background-color: transparent;
              &::after {
                background: var(--link-hover-bg);
              }
            }
          }
        }
      }
      &.router-link-exact-active {
        background: var(--gradient1);
        color: var(--surface-a);
        opacity: 1;
        .sidebar-hide:not(.sidebar-hover) & {
          @include bp(smd-min-2) {
            background: transparent;
            &::after {
              background: var(--gradient1);
            }
          }
        }
      }
      .p-badge {
        margin-left: auto;
      }
    }
    &.active-menuitem {
      > a {
        .menuitem-toggle-icon {
          &:before {
            content: '\e933';
          }
        }
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      &.layout-submenu-wrapper-enter {
        max-height: 0;
      }
      &.layout-submenu-wrapper-enter-active {
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
      }
      &.layout-submenu-wrapper-enter-done {
        transform: none;
      }
      &.layout-submenu-wrapper-exit {
        max-height: 1000px;
      }
      &.layout-submenu-wrapper-exit-active {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
      }
      ul {
        padding-left: 1rem;
      }
    }
  }
}

.menu-link {
  display: flex;
}

.p-menu {
  width: 13.5rem;
}

.pi {
  &.custom-ic {
    &::before {
      background-size: 15px;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      display: block;
      background-position: center;
    }
  }
  &.technology-ic {
    &::before {
      background-size: 17px;
      content: '';
      background-image: url(../../images/technology.svg);
      .dark-theme & {
        background-image: url(../../images/technology-dark.svg);
      }
      .router-link-exact-active & {
        background-image: url(../../images/technology-white.svg);
      }
    }
  }
  &.clock-ic {
    &::before {
      content: '';
      background-image: url(../../images/clock.svg);
      background-size: 16px;
      .dark-theme & {
        background-image: url(../../images/clock-dark.svg);
      }
      .router-link-exact-active & {
        background-image: url(../../images/clock-white.svg);
      }
    }
  }
  &.interviews-ic {
    &::before {
      content: '';
      background-image: url(../../images/interviews.svg);
      .dark-theme & {
        background-image: url(../../images/interviews-dark.svg);
      }
      .router-link-exact-active & {
        background-image: url(../../images/interviews-white.svg);
      }
    }
  }
}

@media (max-height: 700px) and (orientation: landscape) {
  .sidebar-graphic-block {
    margin-top: 10px;
    img {
      width: 100px;
    }
  }
  .layout-menu {
    height: calc(100vh - 100px);
  }
}
@media (max-height: 700px) and (max-width: 991px) and (orientation: landscape) {
  .sidebar-graphic-block {
    display: none;
  }
  .layout-menu {
    height: calc(100vh - 100px);
  }
}
.sidebar-curve {
  position: fixed;
  left: 250px;
  top: 0;
  background-color: #fff;
  z-index: 991;
  transition: left $transitionDuration;
  .sidebar-hide:not(.sidebar-hover) & {
    left: 80px;
  }
  @include bp(smd-max) {
    display: none;
  }
  .dark-theme & {
    background-color: var(--dark-boxes);
  }
  img {
    display: block;
    width: 50px;
    @include bp(llg-max) {
      width: 24px;
    }
    &.dark-theme-curve {
      display: none;
      .dark-theme & {
        display: block;
      }
    }
    &.light-theme-curve {
      display: block;
      .dark-theme & {
        display: none;
      }
    }
  }
  // background-color: #fff;
  &.bottom-curve {
    bottom: 0;
    top: auto;
  }
}

.subMenu {
  margin: 0 0 10px;
}
