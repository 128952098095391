.swiper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  .arrow-left {
    z-index: 100;
    left: -3px;
    color: blue;
  }
  .arrow-right {
    z-index: 100;
    right: -3px;
    color: blue;
  }
  .disabled-arrow {
    color: gray;
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  min-width: 11rem;
  border-radius: var(--border-radius);
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.swiper-button-prev {
  content: ' ';
  display: none !important;
}
.swiper-button-next {
  content: ' ';
  display: none !important;
}

.swiper-highlight-border {
  border: 3px solid var(--blue-400);
}
