input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11.5px 14px !important;
}
