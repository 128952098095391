.user-profile-wrapper {
  padding-bottom: 20px;
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .left-col {
      max-width: calc(37% - 13px);
      flex: 0 0 calc(37% - 13px);
      @include bp(sm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
      }
      .form-col {
        background: var(--surface-a);
        box-shadow: 1px 1px 3px 1px rgba(58, 54, 68, 0.03);
        border-radius: $borderRadius;
        min-height: 437px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include bp(sm-max) {
          min-height: unset;
        }
        .dark-theme & {
          background-color: var(--dark-boxes);
        }
      }
      .p-error {
        display: block;
        text-align: center;
        padding: 10px 10px 0;
      }
      .profile-box {
        padding: 13px 23px 13px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include bp(sm-max) {
          padding: 25px 15px 15px;
        }
        .dark-theme & {
          border-color: var(--border-light-color);
        }
      }
      .profile-img {
        text-align: center;
        .MuiAvatar-root,
        img {
          width: 120px;
          height: 120px;
          border-radius: $borderRadius;
          margin: 0 auto 20px;
          object-fit: cover;
        }
      }
      .user-details {
        margin-bottom: 30px;
        @include bp(sm-max) {
          margin-bottom: 20px;
        }
        .user-name {
          font-weight: 400;
          font-size: 20px;
          line-height: 16px;
          color: var(--heading-text);
          @include bp(sm-max) {
            font-size: 18px;
          }
          .dark-theme & {
            color: var(--title-dark-text);
          }
        }
      }
      .edit-delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-color);
          background: none;
          padding: 0;
          box-shadow: none;
          outline: 0;
          @include bp(sm-max) {
            font-size: 15px;
          }
          span {
            font-weight: 400;
            .dark-theme & {
              color: var(--title-light-text2);
            }
          }
          &.edit-btn {
            margin-right: 57px;
            padding-left: 3px;
          }
        }
      }
      .notification-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 23px;
        border-top: 1px solid var(--light-gray4);
        @include bp(lgl-max) {
          padding: 15px;
        }
        .dark-theme & {
          border-color: var(--border-light-color);
        }
        label {
          font-weight: 400 !important;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-color);
          @include bp(sm-max) {
            font-size: 15px;
          }
          .dark-theme & {
            color: var(--title-light-text2);
          }
        }
        .p-inputswitch {
          width: 48px;
          height: 28px;
          @include bp(sm-max) {
            width: 40px;
            height: 24px;
          }
          .p-inputswitch-slider {
            width: 48px;
            height: 28px;
            background-color: var(--light-gray5);
            border-radius: 20px;
            @include bp(sm-max) {
              width: 40px;
              height: 24px;
              border-radius: 30px;
            }
            &:before {
              width: 18px;
              height: 18px;
              margin-top: -9px;
              @include bp(sm-max) {
                width: 16px;
                height: 16px;
                margin-top: -8px;
              }
            }
          }
          &.p-inputswitch-checked {
            .p-inputswitch-slider {
              background-color: var(--primary-color);
              &:before {
                transform: translateX(22px);
                -webkit-transform: translateX(22px);
                -moz-transform: translateX(22px);
                -ms-transform: translateX(22px);
                -o-transform: translateX(22px);
                @include bp(sm-max) {
                  transform: translateX(17px);
                  -webkit-transform: translateX(17px);
                  -moz-transform: translateX(17px);
                  -ms-transform: translateX(17px);
                  -o-transform: translateX(17px);
                }
              }
            }
          }
        }
        &.reset-password-div {
          justify-content: center;
          @include bp(lgl-max) {
            min-height: 59px;
          }
          .primary-link {
            padding: 0;
            font-weight: 400 !important;
            @include bp(sm-max) {
              font-size: 15px;
            }
          }
        }
      }
    }
    .right-col {
      max-width: calc(63% - 13px);
      flex: 0 0 calc(63% - 13px);
      @include bp(sm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 15px;
      }
      .dark-theme & {
        background-color: var(--dark-boxes);
      }
      // .custom-form-group{
      //   .p-autocomplete{
      //     .p-inputtext{
      //       height: auto;
      //       white-space: normal;
      //       overflow-x: hidden;
      //     }
      //   }
      // }
    }
  }
}
