.pi-eye {
  cursor: pointer;
}
.pi-eye-slash {
  cursor: pointer;
}
.p-dialog {
  background-color: #fff;
}
.p-dialog .p-dialog-content {
  background-color: transparent;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  height: 3px;
}

.p-paginator .p-paginator-current {
  text-align: center;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  white-space: nowrap;
}

.candidate-details {
  display: flex;
  justify-content: space-between;
  @include bp(lgl-max) {
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  .grid {
    margin-top: 0;
    display: block;
    margin: 0;
    @include bp(lgl-max) {
      max-width: 50%;
      flex: 0 0 50%;
      margin-bottom: 20px;
    }
    @include bp(xs-max) {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .col {
      padding: 0;
      &.title {
        margin-bottom: 16px;
        font-weight: 700;
        color: var(--heading-text);
        font-size: 12px;
        text-transform: uppercase;
        .dark-theme & {
          color: var(--title-dark-text);
        }
        @include bp(xs-max) {
          margin-bottom: 10px;
        }
      }
      &.content {
        color: var(--heading-text);
        .dark-theme & {
          color: var(--title-dark-text);
        }
      }
      .pi-file-pdf {
        font-size: 22px;
        color: var(--primary-color);
      }
    }
  }
}
.hr-round-feedback-wrapper {
  margin-top: 20px;
  .feedback-title {
    // margin-bottom: 16px;
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
      display: inline-flex;
      align-items: center;
      position: relative;
      font-weight: 700;
      color: var(--heading-text);
      font-size: 12px;
      text-transform: uppercase;
      .dark-theme & {
        color: var(--title-dark-text);
      }
    }
    .pi-eye {
      cursor: pointer;
      font-size: 18px;
    }
    .dark-theme & {
      color: var(--title-dark-text);
    }
  }
}
.log-detail-popup {
  @include bp(xs-max) {
    width: 100% !important;
  }
  .p-datatable-wrapper {
    min-height: inherit;
  }
  .p-datatable-header {
    padding-right: 0 !important;
  }
  .white-box {
    box-shadow: none;
  }
}

@media (max-width: 1200px) {
  .full-calender-wrapper {
    .fc-header-toolbar {
      flex-wrap: wrap;
      .fc-toolbar-chunk:nth-child(2) {
        order: 2;
        .fc-toolbar-title {
          margin-right: 0;
        }
      }
      .fc-toolbar-chunk:nth-child(1) {
        order: 1;
        width: 100%;
        text-align: center;
        margin: 0 0 1rem;
        .fc-toolbar-title {
          margin-right: 0;
        }
      }
      .fc-toolbar-chunk:last-child {
        order: 3;
      }
    }
  }
}

@media (max-width: 767px) {
  .p-dialog-content {
    > .flex {
      > .font-bold {
        width: 6rem !important;
      }
    }
  }
}

@media (max-width: 575px) {
  .candidate-details {
    .grid {
      .col {
        &.title {
          max-width: 100%;
          flex: 0 0 100%;
        }
        &.content {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
  .full-calender-wrapper {
    .fc-view {
      overflow-x: auto;
      table {
        min-width: 600px;
        .fc-day {
          .fc-col-header-cell-cushion {
            justify-content: flex-start;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }
  .p-tabview-panels {
    .col-12 {
      .col-6 {
        padding: 0 !important;
        margin: 0 0 10px;
      }
    }
  }
}

@media (max-width: 420px) {
  .full-calender-wrapper {
    .fc-header-toolbar {
      .fc-toolbar-chunk:first-child {
        margin: 0 0 1rem;
        width: 100%;
      }
      .fc-toolbar-chunk:nth-child(2) {
        text-align: left;
      }
      .fc-toolbar-chunk:last-child {
        width: 100%;
      }
    }
  }
}

.pi-search {
  cursor: pointer;
}

.Toastify__progress-bar {
  opacity: 0 !important;
}
.Toastify__toast-theme--light {
  padding: 15px;
  min-height: inherit;
  .Toastify__toast-body {
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: 500;
    padding: 0 !important;
    align-items: center;
    svg {
      width: 20px;
      height: auto;
    }
  }
  &.Toastify__toast--success {
    color: #075e45;
    background: #fff;
    svg {
      fill: #075e45;
    }
  }
  &.Toastify__toast--error {
    color: #a1160a;
    background: #fff;
    svg {
      fill: #a1160a;
    }
  }
  &.Toastify__toast--warning {
    // color: #f5c518;
    background: #fff;
    svg {
      fill: #f5c518;
    }
  }
  .Toastify__close-button {
    opacity: 1 !important;
    align-self: center;
    svg {
      color: var(--text-color) !important;
      fill: var(--text-color) !important;
      height: 18px;
      width: 18px;
    }
  }
}

.flex-grow {
  flex-grow: 1;
}

.column-gap {
  column-gap: 1.5em;
}

.p-component {
  font-size: 0.9em;
}
