.card {
  background-color: var(--surface-card);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  // box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
  //   0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  box-shadow: 1px 1px 3px 1px rgba(58, 54, 68, 0.03) !important;
  &.card-w-title {
    padding-bottom: 2rem;
  }
  .dark-theme & {
    background-color: var(--dark-boxes);
  }
}

.interview-card {
  padding: 0;

  .p-tabview-nav-container {
    padding: 0 1.5rem;
  }

  .p-tabview-panels {
    padding: 0 !important;

    .p-datatable-wrapper {
      min-height: calc(100vh - 365px);
    }
  }
}

.interview-calender-view {
  padding: 1.5rem;
}
