.full-calender-wrapper {
  .custom-form-group {
    max-width: 480px;
    margin-bottom: 24px;
  }
}
.schedule-wrapper {
  .grey-card {
    padding: 37px 23px 37px 23px;
    border-radius: 20px;
  }
}
.calendar-wrap {
  position: relative;
  .fc {
    .fc-button-group {
      align-items: center;
      position: inherit;

      .fc-button {
        color: var(--primary-color) !important;
        background-color: transparent;
        border: 1px solid var(--primary-color);
        text-transform: uppercase;
        padding: 0 17px;
        height: 36px;
        line-height: 36px;
        transition: all 0.5s;
        font-size: 14px;
        @include bp(xxxs-max) {
          padding: 0 12px;
          font-size: 13px;
        }
        &:first-child {
          border-radius: 4px 0 0 4px !important;
        }
        &:last-child {
          border-radius: 0 4px 4px 0 !important;
        }
        &.fc-prev-button {
          background-color: transparent !important;
          border: 0 !important;
          background-image: url('../../images/back-arrow.svg') !important;
          background-size: 9px !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          padding: 0;
          height: 35px;
          width: 30px;
          box-shadow: none !important;
          border-radius: 4px !important;
          top: 0;
          border: 0;
          margin-top: -5px;
          span {
            display: none;
          }
          .dark-theme & {
            filter: invert(1) brightness(0.8);
          }
        }
        &.fc-next-button {
          background-color: transparent !important;
          border: 0 !important;
          background-image: url('../../images/back-arrow.svg') !important;
          background-size: 9px !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          padding: 0;
          border: 0;
          height: 35px;
          width: 30px;
          margin-left: 5px;
          box-shadow: none !important;
          border-radius: 4px !important;
          top: 0;
          transform: rotate(180deg);
          margin-top: -6px;
          span {
            display: none;
          }
          .dark-theme & {
            filter: invert(1) brightness(0.8);
          }
        }
        &:not(:last-child) {
          border-right: 1px solid var(--primary-color-overlay3);
        }
        &:focus,
        &:hover,
        &.fc-button-active {
          background-color: var(--primary-color);
          box-shadow: none;
          outline: none;
          color: var(--surface-a) !important;
        }
      }
    }
    .fc-day-other {
      .fc-daygrid-day-number {
        color: var(--light-gray5);
        .dark-theme & {
          color: var(--title-light-text);
          opacity: 0.6;
        }
      }
    }
    .fc-daygrid-day-number {
      color: var(--heading-text);
      font-size: 16px;
      padding-right: 18px;
      padding-top: 15px;
      .dark-theme & {
        color: var(--title-light-text);
      }
    }
    .fc-day-other {
      .fc-daygrid-day-top {
        opacity: 1;
      }
    }
    .fc-daygrid-day.fc-day-today {
      background-color: transparent;
    }
    .fc-toolbar.fc-header-toolbar {
      margin-bottom: 18px;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 0px;
      .fc-toolbar-chunk {
        &:last-child {
          margin-left: auto;
        }
      }
      .fc-toolbar-title {
        font-size: 20px;
        color: var(--heading-text);
        margin-right: 10px;
        .dark-theme & {
          color: var(--title-light-text);
        }
        @include bp(slg-max) {
          position: relative;
          top: auto;
          left: auto;
          transform: none;
          font-size: 20px;
        }
      }
    }
    .fc-daygrid-day-frame {
      min-height: 100px;
      .fc-daygrid-event-harness {
        margin: 5px 0;
      }
    }
  }
  .dropdown-wrap {
    position: absolute;
    top: -6px;
    left: 8px;
  }
  .fc-theme-standard {
    td {
      border-color: var(--light-gray4);
      .dark-theme & {
        border-color: var(--border-light-color);
      }
    }
    th {
      border-color: var(--light-gray4);
      text-align: left;
      .dark-theme & {
        border-color: var(--border-light-color);
      }
      a {
        padding: 10px 15px;
        color: var(--heading-text);
        font-weight: 700;
        font-size: 12px;
        width: 100%;
        text-transform: uppercase;
        background-color: var(--white1);
        .dark-theme & {
          color: var(--title-light-text);
          background-color: var(--surface-body);
        }
      }
    }
    .fc-scrollgrid {
      border-color: var(--light-gray4) !important;
      background-color: var(--surface-f);
      .dark-theme & {
        background: var(--dark-boxes);
        border-color: var(--border-light-color) !important;
      }
    }
    .fc-list {
      border: 0;
    }
    .fc-list-day-cushion {
      background-color: var(--surface-g);
      padding: 6px 15px !important;
      display: flex;
      justify-content: space-between;
      @include bp(xxs-max) {
        padding: 6px 10px !important;
      }
      &:after {
        display: none;
      }
      a {
        padding: 0;
        width: auto;
        order: 2;
        font-weight: 600;
        font-size: 14px;
        color: var(--heading-text);
        line-height: 24px;
        float: none;
        .dark-theme & {
          color: var(--title-light-text);
        }
      }
      .fc-list-day-side-text {
        order: 1;
        margin-right: 5px;
      }
    }
    .fc-list-table {
      .fc-list-day-cushion {
        background-color: var(--white1);
        a {
          background-color: transparent;
        }
        .dark-theme & {
          background-color: var(--surface-body);
        }
      }
      td {
        border-color: var(--surface-g);
        background: #f5f5f5;
        background-color: var(--dark-boxes) !important;
      }
      th {
        background: #f5f5f5;
        background-color: var(--dark-boxes) !important;
        border-top: 0;
        @media (max-width: 574px) {
          border-top: 1px solid var(--light-gray4);
          .dark-theme & {
            border-color: var(--border-light-color) !important;
          }
        }
      }
    }
  }
  .fc-event {
    padding: 6px 8px;
    border-radius: 4px;
    background-color: var(--primary-color-overlay);
    border: 1px solid var(--primary-color-overlay);
    box-shadow: none;
    &:hover {
      background-color: var(--primary-color-overlay) !important;
      border-color: var(--primary-color-overlay) !important;
      color: var(--primary-color) !important;
    }
    .fc-event-main {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }
  .fc-event-time {
    font-weight: 400;
  }
  .fc-daygrid-event-dot {
    display: none;
  }
  .fc-daygrid-dot-event {
    .fc-event-title {
      font-weight: 600;
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .fc-direction-ltr {
    .fc-daygrid-event.fc-event-end {
      margin-right: 5px;
      border: none;
    }
    .fc-daygrid-event.fc-event-start {
      margin-left: 5px;
      border: none;
    }
  }
  .fc-list-table {
    .fc-theme-standard {
      .fc-list-day-cushion {
        background-color: transparent;
      }
    }
    .fc-list-day {
      &:first-child {
        .fc-list-day-cushion {
          margin-top: 0;
          padding-left: 7px;
          .dark-theme & {
            background-color: var(--surface-body);
            color: var(--title-dark-text);
            border-color: var(--border-light-color);
          }
        }
      }
    }
    border: 1px solid var(--light-gray4);
    .dark-theme & {
      border-color: var(--border-light-color);
    }
    border-bottom: 0;
    border-collapse: separate;
    @include bp(xs-max) {
      min-width: auto !important;
    }
    @media (max-width: 574px) {
      border-bottom: 1px solid var(--light-gray4);
      .dark-theme & {
        border-color: var(--border-light-color);
      }
    }
    .fc-list-event {
      background-color: var(--surface-f) !important;
      border: none !important;
      color: var(--heading-text);
      .dark-theme & {
        color: var(--title-light-text);
        background-color: var(--dark-boxes) !important;
      }
      td {
        background-color: var(--surface-f) !important;
        border-bottom: 1px solid var(--light-gray4);
        box-shadow: none;
        padding: 12px 15px;
        color: var(--text-color);
        font-weight: 400 !important;
        font-size: 14px;
        .dark-theme & {
          border-color: var(--border-light-color);
          background-color: var(--dark-boxes) !important;
        }
      }
      .fc-list-event-time {
        min-width: 163px;
        position: relative;
      }
      .fc-list-event-title {
        padding: 12px 15px;
      }
    }
    .fc-list-event-graphic {
      display: none;
    }
  }
  .fc-listMonth-view {
    .inner-list-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right-block {
        margin-left: 20px;
      }
      .icon-listing {
        display: flex;
        align-items: center;
        li + li {
          margin-left: 23px;
        }
        li {
          a {
            display: inline-block;
            width: 24px;
            height: 16px;
            text-align: center;
            background-position: center;
            background-size: cover;
            background-repeat: repeat;
          }
          a.view-link {
            background-image: url('../../images/video-icon.svg');
          }
          a.out-link {
            background-image: url('../../images/out-link.svg');
          }
        }
      }
    }
  }
  .fc-list-event-time {
    font-size: 14px;
    vertical-align: middle;
    font-weight: 700;
  }
  .fc-list-event-title {
    .heading-block {
      h3 {
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: 600;
      }
      p {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .calendar-wrap {
    .fc-daygrid {
      overflow: auto;
    }
    .fc-theme-standard {
      .fc-scrollgrid {
        min-width: 1024px;
      }
      th {
        a {
          padding: 15px 10px;
        }
      }
    }
  }
  .fc {
    .fc-view-harness {
      overflow: hidden;
    }
  }
}
@media (max-width: 991px) {
  .fc-direction-ltr {
    .fc-daygrid-event {
      .fc-event-time {
        font-size: 10px;
      }
    }
  }
  .calendar-wrap {
    .fc-daygrid-dot-event {
      .fc-event-title {
        font-size: 10px;
      }
    }
    .custom-dropdown {
      .dropdown-toggle {
        .dropdown-title {
          font-size: 20px;
        }
        &::after {
          width: 16px;
          height: 16px;
          background-size: 16px 16px;
          top: 18px;
        }
        padding: 0 73px 0 48px;
        min-width: 230px;
      }
    }
    .fc-list-table {
      .fc-list-event {
        .fc-list-event-title {
          padding: 15px;
        }
        .fc-list-event-time {
          min-width: inherit;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .calendar-wrap {
    .fc-today-button {
      margin-left: 10px !important;
      min-width: 110px;
    }
    .custom-dropdown {
      .dropdown-toggle {
        .dropdown-title {
          font-size: 18px;
        }
      }
    }
    .fc-listMonth-view {
      .inner-list-block {
        position: relative;
        .right-block {
          margin: 0;
          position: absolute;
          top: 4px;
          right: 0;
        }
      }
    }
    .fc-list-event-title {
      .heading-block {
        h3 {
          padding-right: 77px;
        }
      }
    }
    .fc-theme-standard {
      .fc-list-day-cushion {
        a {
          font-size: 18px;
          @include bp(xxs-max) {
            font-size: 14px;
          }
        }
      }
    }
    .fc {
      .fc-toolbar.fc-header-toolbar {
        margin-left: 0;
      }
    }
  }
}
@media (max-width: 574px) {
  .calendar-wrap {
    .dropdown-wrap {
      top: 0;
    }
    .fc-listMonth-button {
      display: none;
    }
    .fc-next-button {
      order: 3;
      max-width: 35px;
      flex: 0 0 35px !important;
    }
    .fc-prev-button {
      order: 2;
      max-width: 35px;
      flex: 0 0 35px !important;
      margin-left: auto !important;
    }
    .form-switch-wrapper {
      right: 0;
      top: 110px;
      left: 10px;
    }
    .fc {
      .fc-toolbar.fc-header-toolbar {
        flex-wrap: wrap;
      }
    }
    .fc-list-table {
      .fc-list-event {
        .fc-list-event-title {
          padding: 0 15px 15px 15px;
          display: block;
          border: none !important;
          background-color: transparent !important;
          box-shadow: none !important;
          @include bp(xxs-max) {
            padding: 0 10px 15px;
          }
        }
        .fc-list-event-time {
          min-width: 100%;
          padding: 15px 105px 15px 15px;
          display: block;
          border: none !important;
          background-color: transparent !important;
          box-shadow: none !important;
          position: initial;
          @include bp(xxs-max) {
            padding: 15px 10px;
          }
        }
        box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
        position: relative;
      }
    }
    .fc-listMonth-view {
      .inner-list-block {
        .right-block {
          top: -32px;
        }
      }
    }
    .fc-list-event-title {
      .heading-block {
        h3 {
          padding-right: 0;
        }
      }
    }
  }
}
@media (max-width: 479px) {
  .calendar-wrap {
    .fc-today-button {
      min-width: 100px;
    }
    .fc-prev-button {
      margin-left: auto !important;
    }
  }
}

.fc {
  .fc-timegrid {
    .fc-timegrid-slots {
      .fc-timegrid-slot {
        block-size: 3rem;
      }
    }
    .fc-daygrid-day-frame {
      min-height: inherit;
    }
    .fc-timegrid-divider {
      display: none;
    }
    .fc-v-event {
      .fc-event-title-container {
        flex-grow: inherit;
        flex-shrink: inherit;
        margin-bottom: 5px;
      }
    }
  }
  .fc-timegrid-axis {
    color: var(--heading-text);
    opacity: 0.6;
    font-size: 0.75rem;
    text-transform: capitalize;
    .dark-theme & {
      color: var(--title-light-text);
    }
  }
  .fc-timegrid-axis-frame {
    justify-content: center;
  }
  .fc-timegrid-slot-label-frame {
    color: var(--heading-text);
    .dark-theme & {
      color: var(--title-light-text);
    }
    font-size: 0.75rem;
    text-align: center;
    text-transform: uppercase;
  }
  .fc-timegrid-col {
    &.fc-day-today {
      background-color: var(--surface-g);
      .dark-theme & {
        background-color: var(--surface-body-rgba);
      }
    }
  }
}
.full-calender-wrapper {
  .fc-header-toolbar {
    .fc-toolbar-chunk {
      @include bp(xxs-max) {
        width: 100%;
        text-align: center !important;
      }
      &:last-child {
        @include bp(xxs-max) {
          order: 1 !important;
          margin-bottom: 10px;
        }
      }
      &:first-child {
        @include bp(xxs-max) {
          order: 1 !important;
        }
      }
    }
  }
}
.fc {
  &.fc-theme-standard {
    .fc-view {
      .dark-theme & {
        background: var(--dark-boxes);
      }
    }
    .fc-view-harness {
      .fc-popover {
        border-radius: 6px;
        .fc-popover-header {
          background: var(--surface-g);
          padding: 4px 8px !important;
          border-color: var(--light-gray4);
          .dark-theme & {
            border-color: var(--border-light-color);
            background-color: var(--surface-body);
          }
          .fc-popover-title {
            color: var(--heading-text);
            .dark-theme & {
              color: var(--title-light-text);
            }
          }
          .fc-popover-close {
            color: var(--heading-text);
            .dark-theme & {
              color: var(--title-light-text);
              background-color: transparent !important;
            }
          }
        }
        .fc-popover-body {
          padding: 8px;
          border-color: var(--light-gray4);
          .dark-theme & {
            border-color: var(--border-light-color);
            background-color: var(--dark-boxes);
          }
          .fc-event {
            border: 0;
          }
        }
      }
    }
  }
}
.fc-timegrid-more-link {
  width: 30px;
  background: var(--primary-color);
  color: var(--surface-a);
  top: auto !important;
  height: 30px;
  right: 10px !important;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}
.fc-list-empty-cushion {
  color: var(--text-color);
  .dark-theme & {
    color: var(--title-dark-text);
  }
}
