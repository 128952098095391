.layout-footer {
  transition: margin-left $transitionDuration;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 100%;
  padding: 20px 0;

  @include bp(smd-min) {
    width: calc(100% - 298px);
    margin: 0 24px 0 auto;
  }
  @include bp(xxxl-min) {
    width: calc(100% - 449px);
    margin: 0 100px 0 auto;
  }
  @include bp(smd-max) {
    padding: 15px 15px;
  }
  @include bp(xs-max) {
    flex-direction: column;
  }
  p {
    display: inline-block;
    margin: 0;
    text-align: center;
    font-size: 14px;
    line-height: 1.17;
    color: var(--login-text2);
    &:last-child {
      @include bp(xs-max) {
        margin: 0;
      }
    }
    @include bp(xs-max) {
      margin: 0 0 5px;
    }
    span {
      font-weight: 700;
      padding: 0 6px;
    }
  }
}
